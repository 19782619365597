@import "variables/colors";
@import "variables/fonts";
@import "variables/grid";

// Path
$path: "~@theme/images/";
$jackpotPath: $path + "jackpots/";

// Header
$header-size: 120px;
$header-margin: 32px;

/** Variables for Mobile */

// Header
$m_header-size: 64px;
$m_footer-size: 64px;

// Footer
$footer-1row-height: 101px;
$footer-2row-height: 35px;
$footer-height: $footer-1row-height + $footer-2row-height;
$footer-border-top-width: 2px;
$footer-border-bottom-width: 1px;
$footer-padding: 26px;
$footer-background-image: linear-gradient(
  to bottom,
  fade-out($bg-white-color, 0.75),
  $bg-black-color 100%
);

// Swiper pagination.
$m_swiper-p-size: 16px;
$m_swiper-p-indent: 0;
$m_swiper-p-active: 7px;

$m_footer-btn-height: 52px;
$m_footer-btn-width: 120px;
$m_footer-padding: 18px;
$heart-active-color: $theme-heart-color-active;

/** Add theme style */
@import "~@theme/style/variables.scss";
