@import "~@/style/global.scss";

$jackpotClass: jackpots__jackpot;

// TODO: create sprites for background images

.jackpots {
  display: flex;
  align-items: flex-end;

  .jackpots__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }

  &--float {
    $bezier: cubic-bezier(0.5, 1, 0.6, 1);
    $time: 0.5s;

    left: 0;
    right: 0;
    bottom: 0;
    transition: -webkit-transform $time $bezier;
    -o-transition: transform $time $bezier;
    transition: transform $time $bezier;
    transition: transform $time $bezier, -webkit-transform $time $bezier;
    background-image: none;
    background-color: fade-out($dark-color, 0.4);
    position: fixed;
    z-index: 100;

    @media (max-width: 1024px) and (orientation: portrait) {
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    &.jackpots--visible {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
}

.jackpots--compact {
  position: relative;
  width: 100%;

  @media (min-width: 1025px) {
    .jackpots__container {
      flex-wrap: nowrap;
    }

    .#{$jackpotClass} {
      width: 343px;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: $second-font-family;
      font-weight: bold;
      text-transform: uppercase;
      color: $text-color;
      font-size: $base-font-size;
      position: relative;

      &:nth-child(1) {
        order: 0;
      }

      &:nth-child(2) {
        margin-left: -2px;
        order: 1;
      }

      &:nth-child(3) {
        margin-left: -2px;
        order: 1;
      }

      &:nth-child(4) {
        margin-left: -2px;
        order: 3;
      }

      .jackpots__overlay,
      .jackpots__background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        position: absolute;
      }

      .jackpots__overlay {
        width: 0;
        transition-property: height;
        transition-timing-function: linear;
        filter: grayscale(100%);
        z-index: 200;
      }

      .jackpots__background {
        z-index: 100;
      }

      &:nth-child(1) .jackpots__overlay,
      &:nth-child(1) .jackpots__background {
        background-image: url($jackpotPath + "desktop-compact-ultimate.png");
        background-position-x: left;
      }

      &:nth-child(2) .jackpots__overlay,
      &:nth-child(2) .jackpots__background {
        background-image: url($jackpotPath + "desktop-compact-major.png");
        background-position-x: center;
      }

      &:nth-child(3) .jackpots__overlay,
      &:nth-child(3) .jackpots__background {
        background-image: url($jackpotPath + "desktop-compact-grand.png");
        background-position-x: center;
      }

      &:nth-child(4) .jackpots__overlay,
      &:nth-child(4) .jackpots__background {
        background-image: url($jackpotPath + "desktop-compact-mini.png");
        background-position-x: right;
      }
    }

    .#{$jackpotClass}__value {
      margin-left: 12px;
    }

    .backgroundcliptext & .#{$jackpotClass}__name,
    .backgroundcliptext & .#{$jackpotClass}__value {
      background: $bg-white-color
        linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .#{$jackpotClass}__name,
    .#{$jackpotClass}__value {
      z-index: 300;
      transition-property: opacity;
      transition-timing-function: linear;
    }

    .#{$jackpotClass}--community {
      .jackpots__overlay {
        width: 100%;
      }

      .#{$jackpotClass}__name,
      .#{$jackpotClass}__value {
        opacity: 0.5;
      }
    }
  }

  @media (max-width: 1024px) {
    .jackpots__container {
      flex-wrap: wrap;
    }

    @media (orientation: landscape) {
      flex-wrap: nowrap;
    }

    .#{$jackpotClass} {
      width: 50%;
      height: 58px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $text-color;
      font-family: $second-font-family;
      font-weight: bold;
      font-size: $font-sm-size;
      text-transform: uppercase;
      position: relative;

      @media (orientation: landscape) {
        width: 25%;
      }

      &:nth-child(1) {
        order: 0;
      }

      &:nth-child(2) {
        order: 1;
      }

      &:nth-child(3) {
        order: 1;

        @media (orientation: portrait) {
          margin-top: -3px;
        }
      }

      &:nth-child(4) {
        order: 3;

        @media (orientation: portrait) {
          margin-top: -3px;
        }
      }

      .jackpots__border {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 3px 0 $shadow-gray-color, inset 0 -3px 0 $shadow-gray-color;
        position: absolute;
        z-index: 300;

        &::before {
          content: "";
          top: 3px;
          left: 0;
          bottom: 3px;
          width: 3px;
          display: block;
          position: absolute;
          background-color: $shadow-gray-color;
          background-image: linear-gradient(
            0deg,
            $shadow-gray-color 0%,
            $bg-white-color 50%,
            $shadow-gray-color 100%
          );
        }

        &::after {
          top: 3px;
          right: 0;
          bottom: 3px;
          width: 3px;
          content: "";
          display: block;
          position: absolute;
          background-color: $shadow-gray-color;
          background-image: linear-gradient(
            0deg,
            $shadow-gray-color 0%,
            $bg-white-color 50%,
            $shadow-gray-color 100%
          );
        }
      }

      @media (orientation: portrait) {
        &:nth-child(1) .jackpots__border::after,
        &:nth-child(3) .jackpots__border::after {
          width: 0;
        }
      }
      @media (orientation: landscape) {
        &:nth-child(1) .jackpots__border::after,
        &:nth-child(2) .jackpots__border::after,
        &:nth-child(3) .jackpots__border::after {
          width: 0;
        }
      }

      .jackpots__overlay,
      .jackpots__background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        position: absolute;
      }

      .jackpots__overlay {
        width: 0;
        transition-property: height;
        transition-timing-function: linear;
        filter: grayscale(100%);
        z-index: 200;
      }

      .jackpots__background {
        z-index: 100;
      }

      @media (orientation: portrait) {
        &:nth-child(1) .jackpots__overlay,
        &:nth-child(1) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-portrait-ultimate.png");
        }

        &:nth-child(2) .jackpots__overlay,
        &:nth-child(2) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-portrait-major.png");
        }

        &:nth-child(3) .jackpots__overlay,
        &:nth-child(3) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-portrait-grand.png");
        }

        &:nth-child(4) .jackpots__overlay,
        &:nth-child(4) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-portrait-mini.png");
        }
      }

      @media (orientation: landscape) {
        &:nth-child(1) .jackpots__overlay,
        &:nth-child(1) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-landscape-ultimate.png");
        }

        &:nth-child(2) .jackpots__overlay,
        &:nth-child(2) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-landscape-major.png");
        }

        &:nth-child(3) .jackpots__overlay,
        &:nth-child(3) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-landscape-grand.png");
        }

        &:nth-child(4) .jackpots__overlay,
        &:nth-child(4) .jackpots__background {
          background-image: url($jackpotPath + "mobile-compact-landscape-mini.png");
        }
      }
    }

    .#{$jackpotClass}__value {
      margin-left: 4px;
    }

    .backgroundcliptext & .#{$jackpotClass}__name,
    .backgroundcliptext & .#{$jackpotClass}__value {
      background: $bg-white-color
        linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
      color: $null-color;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .#{$jackpotClass}__name,
    .#{$jackpotClass}__value {
      z-index: 300;
      transition-property: opacity;
      transition-timing-function: linear;
    }

    .#{$jackpotClass}--community {
      .jackpots__overlay {
        width: 100%;
      }

      .#{$jackpotClass}__name,
      .#{$jackpotClass}__value {
        opacity: 0.5;
      }
    }
  }
}

.jackpots:not(.jackpots--compact) {
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  height: 100%;

  .jackpots__container {
    @media (orientation: portrait) and (max-width: 670px) {
      margin-top: -5vh;
    }

    @media (orientation: portrait) and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
    }

    @media (min-width: 1025px) {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      padding: 12px 0;
      margin: 0 80px;
    }

    @media (min-width: 1366px) {
      margin: 0 160px;
    }
  }

  .#{$jackpotClass} {
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;

    @media (orientation: portrait) and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 1025px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .#{$jackpotClass}__name,
    .#{$jackpotClass}__value {
      line-height: $font-line;
      font-family: $second-font-family;
      text-transform: uppercase;
      font-weight: bold;
      color: $text-color;
      @media (min-width: 1025px) {
        filter: drop-shadow(1px 2px 0 fade-out($bg-black-color, 0.25));
      }

      .backgroundcliptext & {
        background: $bg-white-color
          linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
        color: $null-color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &:nth-child(1) {
      @media (orientation: portrait) and (max-width: 1024px) {
        width: 340px;
        height: 102px;
        justify-content: center;
        background-image: url($path + "ultimate-phone.png");
        z-index: 4;
        order: 0;
      }

      @media (orientation: landscape) and (max-width: 1024px) {
        width: 284px;
        height: 82px;
        flex-basis: 50%;
        justify-content: flex-end;
        background-image: url($path + "ultimate-phone-landscape.png");
        background-position: right bottom;
        box-sizing: border-box;
        padding-left: calc(50% - 284px);
        margin: 0;
      }

      @media (min-width: 1025px) {
        width: 50%;
        height: 35px;
        align-items: center;
        background-image: url($path + "ultimate-desktop.png");
        background-position: left bottom;
        box-sizing: border-box;
        padding-right: calc(50% - 340px);
        margin: 0;
      }

      .#{$jackpotClass}__name {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: 62px;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          font-size: 60px;
          margin-bottom: 2px;
        }

        @media (min-width: 1025px) {
          font-size: 36px;
          margin-left: 20px;
          margin-bottom: 12px;
        }
      }

      .#{$jackpotClass}__value {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: 38px;
          margin-bottom: 40px;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          font-size: $font-xxl-size;
          margin-bottom: 16px;
        }

        @media (min-width: 1025px) {
          font-size: $base-font-size;
          margin-right: 20px;
          margin-left: 10px;
        }
      }
    }

    &:nth-child(2) {
      @media (orientation: portrait) and (max-width: 1024px) {
        width: 288px;
        height: 94px;
        justify-content: center;
        background-image: url($path + "major-phone.png");
        margin-top: -4px;
        z-index: 3;
        order: 1;
      }

      @media (orientation: landscape) and (max-width: 1024px) {
        width: 284px;
        height: 82px;
        flex-basis: 50%;
        justify-content: flex-end;
        background-image: url($path + "major-phone-landscape.png");
        background-position: left bottom;
        box-sizing: border-box;
        padding-right: calc(50% - 284px);
        margin: 0 0 0 -2px;
      }

      @media (min-width: 1025px) {
        width: 50%;
        height: 35px;
        align-items: center;
        flex-direction: row-reverse;
        background-image: url($path + "major-desktop.png");
        background-position: right bottom;
        box-sizing: border-box;
        padding-left: calc(50% - 340px);
        margin: 0;
      }

      .#{$jackpotClass}__name {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: 38px;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          font-size: 60px;
          margin-bottom: 2px;
        }

        @media (min-width: 1025px) {
          margin-right: 20px;
          margin-bottom: 12px;
          font-size: $font-xxl-size;
        }
      }

      .#{$jackpotClass}__value {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: $font-xl-size;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          font-size: $font-xxl-size;
          margin-bottom: 16px;
        }

        @media (min-width: 1025px) {
          font-size: $base-font-size;
          margin-left: 24px;
          margin-right: 10px;
        }
      }
    }

    &:nth-child(3) {
      @media (orientation: portrait) and (max-width: 1024px) {
        width: 232px;
        height: 82px;
        justify-content: center;
        background-image: url($path + "mini-phone.png");
        margin-top: -4px;
        z-index: 2;
        order: 3;
      }

      @media (orientation: landscape) and (max-width: 1024px) {
        width: 236px;
        height: 58px;
        flex-basis: 50%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-image: url($path + "mini-phone-landscape.png");
        background-position: right bottom;
        box-sizing: border-box;
        padding-left: calc(50% - 236px);
        margin: -2px 0 0 0;
      }

      @media (min-width: 1025px) {
        width: calc(50% - 60px);
        height: 30px;
        align-items: center;
        background-image: url($path + "mini-desktop.png");
        background-position: left bottom;
        box-sizing: border-box;
        padding-right: calc(50% - 60px - 297px);
        margin: -2px 0 0 60px;
      }

      .#{$jackpotClass}__name {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: $font-xl-size;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          font-size: $font-xl-size;
        }

        @media (min-width: 1025px) {
          font-size: 34px;
          margin-left: 20px;
          margin-top: 10px;
        }
      }

      .#{$jackpotClass}__value {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: $font-xm-size;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          font-size: $base-font-size;
          margin-left: 14px;
        }

        @media (min-width: 1025px) {
          font-size: $base-font-size;
          margin-right: 20px;
          margin-left: 5px;
        }
      }
    }

    &:nth-child(4) {
      @media (orientation: portrait) and (max-width: 1024px) {
        width: 183px;
        height: 77px;
        justify-content: center;
        background-image: url($path + "grand-phone.png");
        margin-top: -5px;
        z-index: 1;
        order: 3;
      }

      @media (orientation: landscape) and (max-width: 1024px) {
        width: 236px;
        height: 58px;
        flex-basis: 50%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-image: url($path + "grand-phone-landscape.png");
        background-position: left bottom;
        box-sizing: border-box;
        padding-right: calc(50% - 236px);
        margin: -2px 0 0 -2px;
      }

      @media (min-width: 1025px) {
        width: calc(50% - 60px);
        height: 30px;
        align-items: center;
        flex-direction: row-reverse;
        background-image: url($path + "grand-desktop.png");
        background-position: right bottom;
        box-sizing: border-box;
        padding-left: calc(50% - 60px - 297px);
        margin: -2px 60px 0 0;
      }

      .#{$jackpotClass}__name {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: $font-xl-size;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          font-size: $font-xl-size;
        }

        @media (min-width: 1025px) {
          font-size: $font-xl-size;
          margin-right: 30px;
          margin-top: 10px;
        }
      }

      .#{$jackpotClass}__value {
        @media (orientation: portrait) and (max-width: 1024px) {
          font-size: $font-md-size;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          margin-left: 14px;
          font-size: $base-font-size;
        }

        @media (min-width: 1025px) {
          font-size: $base-font-size;
          margin-left: 30px;
          margin-right: 8px;
        }
      }
    }

    &--community {
      &:nth-child(1) {
        @media (orientation: portrait) and (max-width: 1024px) {
          background-image: url($path + "ultimate-phone--community.png");
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          background-image: url($path + "ultimate-phone-landscape--community.png");
        }

        @media (min-width: 1025px) {
          background-image: url($path + "ultimate-desktop--community.png");
        }
      }
      &:nth-child(2) {
        @media (orientation: portrait) and (max-width: 1024px) {
          background-image: url($path + "mini-phone--community.png");
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          background-image: url($path + "major-phone-landscape--community.png");
        }

        @media (min-width: 1025px) {
          background-image: url($path + "major-desktop--community.png");
        }
      }
      &:nth-child(3) {
        @media (orientation: portrait) and (max-width: 1024px) {
          background-image: url($path + "major-phone--community.png");
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          background-image: url($path + "mini-phone-landscape--community.png");
        }

        @media (min-width: 1025px) {
          background-image: url($path + "mini-desktop--community.png");
        }
      }
      &:nth-child(4) {
        @media (orientation: portrait) and (max-width: 1024px) {
          background-image: url($path + "grand-phone--community.png");
        }

        @media (orientation: landscape) and (max-width: 1024px) {
          background-image: url($path + "grand-phone-landscape--community.png");
        }

        @media (min-width: 1025px) {
          background-image: url($path + "grand-desktop--community.png");
        }
      }
    }
  }
}
