@import "~@/style/global.scss";

.sweepstakes {
  display: flex;
  align-items: center;
  font-family: $base-font-family;
  font-size: $font-sm-size;
  color: $text-color;

  $r: &;

  &--small {
    @media (orientation: landscape) {
      #{$r}__item + #{$r}__item {
        margin-left: 12px;
      }

      #{$r}__value {
        font-size: $font-sm-size;
      }

      #{$r}__title {
        font-size: 10px;
      }
    }
  }

  &--column {
    @media (orientation: landscape) {
      flex-direction: column;
      justify-content: center;

      #{$r}__item + #{$r}__item {
        margin-left: unset;
        margin-top: 4px;
      }
    }
  }

  &__item + &__item {
    margin-left: 28px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 4px;
  }

  &__title {
    color: $text-color;
    font-family: $base-font-family;
    font-size: 10px;
    text-transform: uppercase;
  }

  &__value {
    color: $text-color;
    font-size: $font-md-size;
    font-family: $base-font-family;
    text-transform: uppercase;
  }
}
