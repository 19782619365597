@import "~@/style/global.scss";

$size: 48px;
$dragSize: 32px;

$size-sm: 40px;
$dragSize-sm: 28px;

.nav {
  position: fixed;
  top: 0;
  display: flex;
  transition: background 0.25s ease, transform 0.5s ease, top 0.5s ease;
  z-index: 110;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 5px 5px;

  $r: &;

  &__wrapper {
    position: relative;
    padding-right: 26px;
    padding-left: 2px;
  }

  @media screen and (orientation: portrait) {
    position: fixed;
    top: unset;
    bottom: 0;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 38px;
    padding: 4px 20px 6px 4px;
    text-align: center;
    margin: 0;
    z-index: 10000;

    &__sweepstakes {
      margin: 0 auto;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 36px;
    }
  }

  @media screen and (orientation: landscape) {
    &--sweepstakes-exchange {
      &--jackpotsVisible {
        #{$r}__btn {
          bottom: 18px;
        }
      }
    }

    &--hide {
      transform: translateX(-100%);

      #{$r}__btn {
        bottom: 50%;

        &::v-deep svg {
          width: 22px;
          height: 22px;
          margin-left: auto;
          transform: scale(-1, 1);
        }
      }
    }

    &--open {
      transform: translateX(0%);

      #{$r}__wrapper {
        padding-right: 16px;
      }

      #{$r}__btn {
        visibility: hidden;
      }
    }

    &--jackpotsVisible {
      top: 58px;

      #{$r}__btn {
        bottom: 18px;
      }
    }

    &__sweepstakes {
      margin-top: 10px;
      margin-bottom: 4px;
    }

    &__btn {
      position: absolute;
      color: $white-color;
      top: 0;
      fill: $white-color;
      border-bottom-right-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.4s ease;
      box-sizing: border-box;
      overflow: hidden;
      z-index: 90;
      padding: 4px 2px;
      right: -30px;
      content: "";
      width: 30px;
      height: 60px;
      background-color: rgba(0, 0, 0, 0.5);

      &::v-deep svg {
        color: transparent;
        fill: white;
        width: 14px;
        height: 14px;
        opacity: 0.8;
      }
    }
  }
}
