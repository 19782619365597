@import "~@/style/global.scss";

.icon {
  transition: transform 0.3s;

  > path {
    transition: transform 0.3s, opacity 0.3s;
  }

  &--screen {
    &.icon--active {
      path:last-child {
        opacity: 0;
      }
    }
  }
}
