@import "~@/style/global.scss";

* {
  font-family: $base-font-family;
}

.wheel {
  display: flex;
  align-items: center;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    &__outer-wrapper {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__outer-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__wheel-wrapper {
    position: relative;
    display: flex;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(52, 73, 94, 0.25);
    transform: translate3d(0, 0, 0);
  }

  &__spin-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__svg-outer {
    position: absolute;
    background-image: url("../assets/images/fortune-wheel.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 770px;
    top: -59px;
    left: -40px;
    bottom: 0;

    &--animation-active {
      &:before {
        content: "";
        position: absolute;
        top: 20px;
        left: 40px;
        width: 700px;
        height: 668px;
        border-radius: 50%;
      }

      &:before {
        box-shadow: 0 0 27px #0f0, inset 0 0 27px #0f0;
        animation: move 6s linear infinite;
      }
    }

    @keyframes move {
      0% {
        box-shadow: 0 0 27px #0f0, inset 0 0 27px #0f0;
        filter: hue-rotate(0deg);
      }
      20% {
        box-shadow: 0 0 60px #0f0, inset 0 0 60px #0f0;
      }

      40% {
        box-shadow: 0 0 40px #0f0, inset 0 0 40px #0f0;
      }
      60% {
        box-shadow: 0 0 80px #0f0, inset 0 0 80px #0f0;
      }
      80% {
        box-shadow: 0 0 100px #0f0, inset 0 0 100px #0f0;
      }
      100% {
        box-shadow: 0 0 27px #0f0, inset 0 0 27px #0f0;
        filter: hue-rotate(360deg);
      }
    }
  }
}

.sector {
  stroke-width: 3px;

  &__figure {
    &--spin {
      @media screen and (max-width: 1024px) {
        stroke: rgba(202, 202, 45, 0.93);
      }
    }
  }

  &__groups {
    transform-origin: center center;
    transform-box: fill-box;
  }

  &__label {
    font-family: $second-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -4px;
    text-transform: uppercase;
    transform-origin: center;
    text-anchor: middle;
    fill: white;
    text-shadow: 2px 2px 4px black;

    @media screen and (max-width: 1024px) {
      text-shadow: 4px 4px 8px black;
    }
  }
}
