@import "resources/variables";
@import "resources/mixins";
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  inset: 0;
}
.right-0 {
  right: 0;
}
.z-\[100000\] {
  z-index: 100000;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.my-3 {
  margin-top: 0.75em;
}
.mb-3,
.my-3 {
  margin-bottom: 0.75em;
}
.mb-4 {
  margin-bottom: 1em;
}
.mb-5 {
  margin-bottom: 1.25em;
}
.ml-1 {
  margin-left: 0.25em;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.size-3 {
  width: 0.75em;
  height: 0.75em;
}
.size-3\.5 {
  width: 0.875em;
  height: 0.875em;
}
.size-5 {
  width: 1.25em;
  height: 1.25em;
}
.size-5\.5 {
  width: 1.375em;
  height: 1.375em;
}
.h-10 {
  height: 2.5em;
}
.h-20 {
  height: 5em;
}
.h-56 {
  height: 14em;
}
.h-full {
  height: 100%;
}
.max-h-\[600px\] {
  max-height: 600px;
}
.max-h-full {
  max-height: 100%;
}
.min-h-0 {
  min-height: 0;
}
.w-\[95\%\] {
  width: 95%;
}
.max-w-\[580px\] {
  max-width: 580px;
}
.animate-pulse {
  -webkit-animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.animate-spin {
  -webkit-animation: tw-spin 1s linear infinite;
  animation: tw-spin 1s linear infinite;
}
.cursor-pointer {
  cursor: pointer;
}
.resize {
  resize: both;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.gap-2 {
  gap: 0.5em;
}
.gap-2\.5 {
  gap: 0.625em;
}
.self-end {
  align-self: flex-end;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-y-auto {
  overflow-y: auto;
}
.whitespace-pre {
  white-space: pre;
}
.rounded-rounded {
  border-radius: 0.25rem;
}
.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.border-0 {
  border-width: 0;
}
.border-1 {
  border-width: 0.0625rem;
}
.border-b-1 {
  border-bottom-width: 0.0625rem;
}
.border-b-2 {
  border-bottom-width: 0.125rem;
}
.border-t-2 {
  border-top-width: 0.125rem;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-\[\#333\]\/30 {
  border-color: rgba(51, 51, 51, 0.3);
}
.border-mercury {
  --tw-border-opacity: 1;
  border-color: rgb(231 231 231 / var(--tw-border-opacity));
}
.border-b-transparent {
  border-bottom-color: transparent;
}
.bg-athens-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 249 / var(--tw-bg-opacity));
}
.bg-black\/80 {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-keppel {
  --tw-bg-opacity: 1;
  background-color: rgb(144 197 114 / var(--tw-bg-opacity));
}
.bg-mercury {
  --tw-bg-opacity: 1;
  background-color: rgb(231 231 231 / var(--tw-bg-opacity));
}
.bg-radical-red {
  --tw-bg-opacity: 1;
  background-color: rgb(255 87 87 / var(--tw-bg-opacity));
}
.bg-silver {
  --tw-bg-opacity: 1;
  background-color: rgb(204 204 204 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.fill-keppel {
  fill: #90c572;
}
.fill-radical-red {
  fill: #ff5757;
}
.px-4 {
  padding-left: 1em;
  padding-right: 1em;
}
.px-5 {
  padding-left: 1.25em;
  padding-right: 1.25em;
}
.py-2 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.py-2\.5 {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
}
.py-3 {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.pb-2 {
  padding-bottom: 0.5em;
}
.text-center {
  text-align: center;
}
.text-\[0\.625em\] {
  font-size: 0.625em;
}
.text-\[0\.875em\] {
  font-size: 0.875em;
}
.text-\[1\.5em\] {
  font-size: 1.5em;
}
.text-\[1em\] {
  font-size: 1em;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.uppercase {
  text-transform: uppercase;
}
.leading-\[1em\] {
  line-height: 1em;
}
.text-\[\#474f51\] {
  --tw-text-opacity: 1;
  color: rgb(71 79 81 / var(--tw-text-opacity));
}
.text-blue-bayoux {
  --tw-text-opacity: 1;
  color: rgb(84 107 129 / var(--tw-text-opacity));
}
.text-dusty-gray {
  --tw-text-opacity: 1;
  color: rgb(151 151 151 / var(--tw-text-opacity));
}
.text-keppel {
  --tw-text-opacity: 1;
  color: rgb(144 197 114 / var(--tw-text-opacity));
}
.text-tangerine {
  --tw-text-opacity: 1;
  color: rgb(221 156 1 / var(--tw-text-opacity));
}
.shadow-\[0_0_5px_rgba\(0\2c 0\2c 0\2c 0\.68\)\] {
  --tw-shadow: 0 0 5px rgba(0, 0, 0, 0.68);
  --tw-shadow-colored: 0 0 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent),
    var(--tw-shadow);
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
    var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
    var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.flex-center {
  justify-content: center;
}
.flex-c-sb,
.flex-center {
  display: flex;
  align-items: center;
}
.flex-c-sb {
  justify-content: space-between;
}
.scrollbar-thin {
  scrollbar-width: thin;
}
.\[scrollbar-color\:\#43ada880_\#f6f7f9\] {
  scrollbar-color: rgba(67, 173, 168, 0.5019607843137255) #f6f7f9;
}
@-webkit-keyframes tw-pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes tw-pulse {
  50% {
    opacity: 0.5;
  }
}
@-webkit-keyframes tw-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes tw-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.empty\:hidden:empty {
  display: none;
}
.hover\:text-tangerine:hover {
  --tw-text-opacity: 1;
  color: rgb(221 156 1 / var(--tw-text-opacity));
}
@media (prefers-color-scheme: dark) {
  .dark\:bg-bright-gray {
    --tw-bg-opacity: 1;
    background-color: rgb(30 32 34 / var(--tw-bg-opacity));
  }
  .dark\:\[scrollbar-color\:\#13c7bc80_\#394250\] {
    scrollbar-color: rgba(19, 199, 188, 0.5019607843137255) #394250;
  }
}
.\[\&\&\]\:border-tangerine.\[\&\&\]\:border-tangerine {
  --tw-border-opacity: 1;
  border-color: rgb(221 156 1 / var(--tw-border-opacity));
}
