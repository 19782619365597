@import "~@/style/global.scss";

.jackpot-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: fade-out($bg-black-color, 0.1);
  position: fixed;
  z-index: 999998;
}

.fortune-window {
  width: 390px;
  height: 460px;
  min-width: 390px;
  max-width: 390px;
  min-height: 460px;
  max-height: 460px;
  position: relative;

  &__title-0 {
    top: -110px;
    left: 50%;
    width: 285px;
    height: 96px;
    background-image: url($path + "fortune-window__title.png");
    position: absolute;
    margin-left: -142px;
  }

  &__suggestion {
    left: 0;
    right: 0;
    bottom: -50px;
    color: $text-color;
    font-family: $base-font-family;
    font-size: 20px;
    text-align: center;
    text-shadow: 0 0 5px $shadow-bright-color;
    position: absolute;
  }

  &__ring {
    top: 50%;
    left: 50%;
    width: 276px;
    height: 140px;
    transform-style: preserve-3d;
    position: absolute;
    margin-top: -70px;
    margin-left: -138px;
  }

  &__jackpots {
    top: 67px;
    left: 57px;
    width: 276px;
    height: 302px;
    perspective: 1600px;
    position: absolute;
    overflow: hidden;
  }

  &__jackpot {
    width: 276px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    &--red {
      background-image: url($path + "fortune-window__red-jackpot.png");
    }

    &--blue {
      background-image: url($path + "fortune-window__blue-jackpot.png");
    }
  }

  &__title {
    font-size: 52px;
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: bold;
    color: $text-color;

    .backgroundcliptext &,
    .backgroundcliptext & {
      background: $bg-white-color
        linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  &__background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url($path + "fortune-window__background.png");
    position: absolute;

    &::before {
      content: "";
      top: 67px;
      left: 57px;
      width: 276px;
      height: 302px;
      display: block;
      background-image: url($path + "fortune-window__frame.png");
      position: absolute;
    }
  }

  &__button {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url($path + "fortune-window__button.png");
    cursor: pointer;
    z-index: 97;

    span {
      color: $text-color;
      font-size: $font-sm-size;
      font-family: $second-font-family;
      text-wrap: balance;
      word-break: break-word;
      inline-size: 80px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      z-index: 100;
    }

    &::after,
    &::before {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background-image: url($path + "fortune-window__button.png");
      transition: opacity 0.25s;
      position: absolute;
      cursor: pointer;
      opacity: 0;
    }

    &::after {
      background-position: -90px -0px;
      z-index: 98;
    }

    &::before {
      background-position: -180px -0px;
      z-index: 99;
    }

    &:hover::after,
    &:active::before {
      opacity: 1;
    }

    &--disabled {
      &::before,
      &:hover::before,
      &:active::before {
        opacity: 1;
        cursor: default;
        pointer-events: none;
      }
    }

    &--spin {
      left: 50%;
      bottom: 12px;
      position: absolute;
      margin-left: -45px;
    }

    &--take {
      left: 50%;
      bottom: 12px;
      position: absolute;
      margin-left: -45px;
    }
  }

  &__lever-wrapper {
    top: 0;
    right: -76px;
    bottom: 0;
    width: 77px;
    position: absolute;
    cursor: pointer;

    &--active {
      cursor: default;
    }

    .fortune-window__lever {
      top: 28px;
      width: 77px;
      height: 202px;
      background-image: url($path + "fortune-window__lever.png");
      transition: transform 0.25s;
      transform-origin: bottom left;
      position: absolute;

      &-ball {
        content: "";
        top: 15px;
        left: 52px;
        width: 35px;
        height: 35px;
        display: block;
        background-image: url($path + "fortune-window__lever-ball.png");
        transition: transform 0.25s;
        position: absolute;
      }
    }

    &--active {
      .fortune-window__lever {
        transform: scaleY(-1);

        &-ball {
          transform: translateY(390px);
        }
      }
    }
  }
}

.congratulations-window {
  width: 488px;
  height: 520px;
  min-width: 488px;
  max-width: 488px;
  min-height: 520px;
  max-height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url($path + "congratulations-window__background.png");
  position: relative;

  &::after {
    content: "";
    top: 0;
    width: 669px;
    height: 472px;
    display: block;
    pointer-events: none;
    background-image: url($path + "congratulations-window__lines.png");
    position: absolute;
  }

  &__title {
    top: -56px;
    width: 608px;
    height: 41px;
    background-image: url($path + "congratulations-window__title.png");
    position: absolute;
  }

  &__title-1 {
    top: 32px;
    left: 72px;
    color: $text-gray-color;
    font-size: $font-xxl-size;
    font-family: $base-font-family;
    text-transform: uppercase;
    position: absolute;
  }

  &__title-2 {
    color: $text-color;
    font-family: $second-font-family;
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    line-height: $font-line;
    transform: rotateZ(-20deg);
    margin-bottom: 35px;
    text-shadow: 0 0 25px $shadow-pink-color, 0 0 20px $shadow-pink-color,
      0 0 15px $shadow-pink-color;
  }
}

.expired-window {
  width: 460px;
  height: 460px;
  background-color: fade-out($bg-black-color, 0.3);
  box-shadow: 0 0 3px $shadow-brighten-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1024px) {
    max-width: 90vw;
    max-height: 90vh;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 90vw;
    height: 90vw;
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    width: 90vh;
    height: 90vh;
  }

  @media (min-width: 1025px) {
    width: 460px;
    height: 460px;
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(
      ellipse,
      fade-out($bg-bright-color, 0.25) 0%,
      $null-color 50%
    );
  }

  &__text {
    padding: 0 24px;
    font-size: 36px;
    font-family: $base-font-family;
    color: $text-color;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      font-size: $font-xm-size;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    margin-bottom: 24px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-lg-size;
    font-family: $base-font-family;
    text-align: center;
    color: $text-color;
    text-transform: uppercase;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-image 0.5s;

    &--blue {
      background-image: url($path + "bonus-modal__button--blue.png");

      &:hover {
        background-image: url($path + "bonus-modal__button--blue-hover.png");
      }

      &:active {
        background-image: url($path + "bonus-modal__button--blue-active.png");
      }
    }

    &--red {
      background-image: url($path + "bonus-modal__button--red.png");

      &:hover {
        background-image: url($path + "bonus-modal__button--red-hover.png");
      }

      &:active {
        background-image: url($path + "bonus-modal__button--red-active.png");
      }
    }

    @media (max-width: 1024px) {
      font-size: $base-font-size;
      width: 128px;
      height: 58px;
    }

    @media (min-width: 1025px) {
      width: 194px;
      height: 88px;
    }
  }
}

@keyframes text-shadow-blink {
  from {
    filter: drop-shadow(0 0 0 $shadow-pink-color);
  }

  50% {
    filter: drop-shadow(0 0 25px $shadow-pink-color) drop-shadow(0 0 20px $shadow-pink-color)
      drop-shadow(0 0 15px $shadow-pink-color);
  }

  to {
    filter: drop-shadow(0 0 0 $shadow-pink-color);
  }
}
