@import "~@/style/global.scss";

.row {
  display: flex;
  margin: 4px 0;
}

.modal {
  &__height-wrapper {
    max-height: calc(100% - 50px);
    overflow-y: scroll;
    width: 100%;
    margin-bottom: auto;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 6px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $btn-primary-color;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $btn-primary-color;
    }
  }

  &__jackpots {
    margin-bottom: 40px;
  }

  &__extra-value {
    font-size: 16px;
    font-weight: bold;
  }

  &__bonuses-not-found {
    text-align: center;
  }

  &__jackpots-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__static-bonus-info {
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      * div {
        font-size: 16px;
      }
    }
  }

  &__jackpots-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__bonuses {
    margin-bottom: 40px;
  }

  &__bonuses-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__bonuses-text {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__cancellation-bonuses {
    margin-bottom: 40px;
  }

  &__cancellation-bonuses-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__cancellation-bonuses-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__cashback {
    margin-bottom: 40px;
  }

  &__cashback-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__cashback-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__happy-hours {
    margin-top: 40px;
  }

  &__happy-hours-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 4px;
  }

  &__happy-hours-title-wrapper {
    display: flex;
  }

  &__happy-hours-text {
    font-size: 14px;
    line-height: 1.7;
  }

  &__wager-coeff {
    display: flex;
    align-items: center;
  }

  &__wager-coeff-title {
    font-size: 14px;
    margin-right: 4px;
  }

  &__bounceback {
    margin-bottom: 40px;
  }

  &__bounceback-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__bounceback-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__last-chance {
    margin-bottom: 20px;
  }

  &__last-chance-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__last-chance-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__current-bonus-type {
    display: flex;
  }

  &__current-bonus-type-title {
    font-size: 18px;
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__current-bonus-type-text {
    font-size: 18px;
    margin-left: 4px;
    color: $text-main-color;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__balance {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;

    &--center {
      justify-content: center;
    }
  }

  &__bonus-balance,
  &__remaining-wager {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &__remaining-wager-value,
  &__bonus-balance-value,
  &__balance-value {
    margin-left: 4px;
    font-size: 18px;
    font-weight: bold;
  }

  &__remaining-wager-title,
  &__bonus-balance-title,
  &__balance-title {
    font-size: 18px;
  }
}

:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}

:deep .modal__content {
  background: none;
  overflow-y: hidden;
  padding: 5px 0;
  border: none;
  resize: none;
  min-width: 420px;
  color: white;
  font-size: $font-sm-size;
  font-family: $base-font-family, $fallback-font-family;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 760px) {
    min-width: auto;
    padding: 10px 10px;
    text-align: left;
  }
}

:deep .modal__footer {
  padding: 0;
  margin-bottom: 0;

  @media screen and (max-width: 760px) {
    padding: 0;
    margin-bottom: 12px;
  }
}

:deep .modal__window {
  max-width: 700px;
  max-height: 800px;
  padding: 40px;
  //overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 100%;
  }
}

:deep .modal__header {
  font-style: italic;
}

:deep .modal__content {
  align-items: flex-start;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.back-button {
  width: 190px;
  height: 40px;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0 auto;

  @media screen and (max-width: 720px) {
    width: 240px;
    margin-top: -26px;
  }
}

@keyframes blue-neon {
  0%,
  100% {
    text-shadow: 0 0 1px $shadow-color, 0 0 3px $shadow-color, 0 0 10px $shadow-color,
      0 0 10px $shadow-color, 0 0 0.4px $shadow-lighten-color, 0.5px 0.5px 0.1px $shadow-dim-color;
    color: $text-light-color;
  }
  50% {
    text-shadow: 0 0 0.5px $shadow-dark-color, 0 0 1.5px $shadow-dark-color,
      0 0 5px $shadow-dark-color, 0 0 5px $shadow-dark-color, 0 0 0.2px $shadow-dark-color,
      0.5px 0.5px 0.1px $shadow-darkDim-color;
    color: $text-dim-color;
  }
}
