@import "~@/style/global.scss";

.providers {
  display: flex;
  flex-direction: row;

  @media (min-width: 1025px) and (max-width: 1320px) {
    margin-left: calc((1320px - 100vw) * 0.75);
  }
}

.provider {
  height: 30px;
  white-space: nowrap;
  padding: 0 1em;

  & + & {
    margin-left: 4px;
  }

  //&::v-deep.btn--active {
  //  background-color: $null-color;
  //}
}
