// Free spins
.flashing {
  color: #fb4264;
  font-size: $font-lg-size;
  font-family: $base-font-family;
  text-shadow: 0 0 3px $coral-color;
  text-transform: uppercase;
  animation: red-neon-data 2s linear infinite;
  -moz-animation: red-neon-data 2s linear infinite;
  -webkit-animation: red-neon-data 2s linear infinite;
  -o-animation: red-neon-data 2s linear infinite;
}

// Vue animations
// @see https://ru.vuejs.org/v2/guide/transitions.html

// Start enter animation
.v-enter {
  opacity: 0;
}

// Animation enter in progress
.v-enter-active {
  transition: opacity 0.5s;
}

// End enter animation
//.v-enter-to {
//  ...
//}

// Start leave animation
//.v-leave {
//  ...
//}

// Animation leave in progress
.v-leave-active {
  transition: opacity 0.5s;
}

// End leave animation
.v-leave-to {
  opacity: 0;
}

/** Game card */

@include VueShow("game") {
  transform: scale(0);
  opacity: 0;
}

@include VueHide("game") {
  transform: scale(1);
  opacity: 1;
}

// Keyframes

@keyframes red-neon-data {
  0%,
  to {
    text-shadow: 0 0 1px $red-color, 0 0 3px $red-color, 0 0 10px $red-color, 0 0 10px $red-color,
      0 0 0.4px $gold-color, 0.5px 0.5px 0.1px $brown-color;
    color: $gold-color;
  }

  50% {
    text-shadow: 0 0 0.5px $tomato-color, 0 0 1.5px $tomato-color, 0 0 5px $tomato-color,
      0 0 5px $tomato-color, 0 0 0.2px $tomato-color, 0.5px 0.5px 0.1px $choco-color;
    color: $brown-color;
  }
}
