@import "~@/style/global.scss";

.page {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__jackpotsWrapper {
    position: fixed;
    bottom: 100%;
    width: 100%;
  }
}
