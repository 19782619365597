@import "~@/style/global.scss";

.extra-value {
  color: #d09f29;
  font-weight: 700;
}

.theme-section-bonuses-description {
  content-visibility: hidden;

  svg {
    width: 48px;
    height: 48px;
  }
}
