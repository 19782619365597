@import "~@/style/global.scss";

.desktop-mini-footer {
  height: 36px;
  display: flex;
  border-top: 1px solid $border-gray-color;
  background-color: $bg-black-color;
  position: relative;

  &__sweepstakes {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  &__row {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
  }

  &__side {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  &__center {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  &__username {
    color: $text-darkGray-color;
    font-size: $font-xs-size;
    font-weight: bold;
    font-family: $second-font-family;
    text-transform: uppercase;
    margin-right: 90px;
  }

  .desktop-mini-footer__text {
    top: 0;
    bottom: 0;
    left: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
  }

  &__icon {
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;

    &--fullscreen {
      background-image: url($path + "fullscreen-icon.png");
    }

    &--close {
      background-image: url($path + "close-icon.png");
    }

    & + & {
      margin-left: 8px;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
