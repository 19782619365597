@import "~@/style/global.scss";

.desktop-footer {
  width: 100%;
  height: $footer-height;
  display: flex;
  flex-direction: column;
  background-color: $bg-black-color;

  @media (max-width: 1024px) {
    display: none;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 $footer-padding;

    &--first {
      height: $footer-1row-height;
      border-top: $footer-border-top-width solid #737375;
      background-image: $footer-background-image;
      border-bottom: $footer-border-bottom-width solid $border-gray-color;
      box-sizing: border-box;
    }

    &--second {
      flex: 1;
      height: $footer-2row-height;
    }
  }

  &__center {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  &__side {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  &__credit {
    align-items: flex-start;
  }

  &__cashback {
    align-items: flex-end;

    &-title {
      font-size: 20px;
      color: $text-main-color;
      text-transform: uppercase;
      text-shadow: 0 0 3px $shadow-light-color;
      animation: blue-neon 2s linear infinite;
      -moz-animation: blue-neon 2s linear infinite;
      -webkit-animation: blue-neon 2s linear infinite;
      -o-animation: blue-neon 2s linear infinite;

      &--red {
        color: $text-coral-color;
        text-shadow: 0 0 3px $shadow-coral-color;
        animation: red-neon 2s linear infinite;
        -moz-animation: red-neon 2s linear infinite;
        -webkit-animation: red-neon 2s linear infinite;
        -o-animation: red-neon 2s linear infinite;
      }
    }
  }

  &__sweepstakes {
    display: flex;
    align-items: center;

    &-item + &-item {
      margin-left: 24px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;
    }

    &-title {
      margin: 0 auto;
      color: $text-color;
      font-family: $base-font-family;
      font-size: $font-xxs-size;
      text-transform: uppercase;
    }

    &-value {
      margin: 0 auto;
      color: $text-color;
      font-size: $font-lg-size;
      font-family: $base-font-family;
      text-transform: uppercase;
    }
  }

  &__credit,
  &__cashback {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-title {
      color: $text-color;
      font-family: $base-font-family;
      font-size: $font-sm-size;
      text-transform: uppercase;
    }

    &-value {
      color: $text-color;
      font-size: $font-xxl-size;
      font-family: $base-font-family;
      text-transform: uppercase;

      .backgroundcliptext & {
        color: transparent;
        background: $bg-white-color
          linear-gradient(to bottom, $bg-white-color, fade-out($bg-black-color, 0.65) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__username {
    color: $text-darkGray-color;
    font-size: $font-xs-size;
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 90px;

    @media (min-width: 1025px) and (max-width: 1320px) {
      margin-right: 50px;
    }
  }

  &__button {
    height: 30px;
    font-size: $font-sm-size;
    text-transform: uppercase;

    &--lang {
      width: 60px;
    }

    &--contacts {
      margin-left: auto;
    }

    //#de_locale
    //&--sign-in,
    //&--sign-out {
    //  width: 78px;
    //}

    & + & {
      &--take {
        margin-left: 48px;
      }

      margin-left: 2px;
    }
  }
}

@keyframes blue-neon {
  0%,
  100% {
    text-shadow: 0 0 1px $shadow-color, 0 0 3px $shadow-color, 0 0 10px $shadow-color,
      0 0 10px $shadow-color, 0 0 0.4px $shadow-lighten-color, 0.5px 0.5px 0.1px $shadow-dim-color;
    color: $text-light-color;
  }
  50% {
    text-shadow: 0 0 0.5px $shadow-dark-color, 0 0 1.5px $shadow-dark-color,
      0 0 5px $shadow-dark-color, 0 0 5px $shadow-dark-color, 0 0 0.2px $shadow-dark-color,
      0.5px 0.5px 0.1px $shadow-darkDim-color;
    color: $text-dim-color;
  }
}

@keyframes red-neon {
  0%,
  100% {
    text-shadow: 0 0 1px $shadow-red-color, 0 0 3px $shadow-red-color, 0 0 10px $shadow-red-color,
      0 0 10px $shadow-red-color, 0 0 0.4px $shadow-red-color, 0.5px 0.5px 0.1px $brown-color;
    color: $text-gold-color;
  }
  50% {
    text-shadow: 0 0 0.5px $shadow-tomato-color, 0 0 1.5px $shadow-tomato-color,
      0 0 5px $shadow-tomato-color, 0 0 5px $shadow-tomato-color, 0 0 0.2px $shadow-tomato-color,
      0.5px 0.5px 0.1px $choco-color;
    color: $text-brown-color;
  }
}
