@import "~@/style/global.scss";

.brands {
  $item: brands__item;
  $prev: swiper-slide-prev;
  $next: swiper-slide-next;
  $active: swiper-slide-active;

  $brands-width: 778px;
  $brands-height: 93px;

  .swiper-container {
    mask-image: linear-gradient(
      to right,
      $null-color 0%,
      $bg-black-color 40%,
      $bg-black-color 60%,
      $null-color 100%
    );
  }

  padding: 0 20px;
  z-index: 100;

  @media (max-width: 1024px) {
    $pagination_size: $m_swiper-p-indent + $m_swiper-p-size + $m_swiper-p-active;

    height: 100%;
    min-width: 100%;
    box-sizing: border-box;
    padding: 0 $pagination_size;
  }

  @media (min-width: 1025px) {
    left: 50%;
    bottom: $footer-2row-height + $footer-border-bottom-width;
    width: $brands-width;
    max-width: 50%;
    height: $brands-height;
    position: absolute;
    background-image: radial-gradient(
      ellipse at top,
      fade-out($bg-black-color, 0.9),
      fade-out($bg-black-color, 0.5) 100%
    );
    transform: translateX(-50%);
  }

  @media (min-width: 1025px) and (max-width: 1279px) {
    width: 430px;
  }

  @media (min-width: 1280px) and (max-width: 1320px) {
    width: 550px;
  }

  &--hidden {
    display: none !important;
  }

  &__items {
    width: 100%;
    height: 100%;
  }

  &__item {
    transition: transform 0.3s;

    @media (min-width: 1025px) {
      &:hover {
        background: radial-gradient(
          ellipse at center,
          fade-out($bg-white-color, 0.75) 0,
          fade-out($bg-white-color, 0.825) 25%,
          $null-color 50%,
          $null-color 100%
        );
      }
    }

    &.swiper-slide {
      $offset: 20px;

      &-prev {
        transform: translateX(-$offset);
      }

      &-active {
        transform: translateX(0);
      }

      &-next {
        transform: translateX($offset);
      }
    }
  }

  &__arrow {
    $padding: 15px;
    $offset: 15px;

    $width: 22px;
    $height: 21px;
    $wrapper_height: $footer-1row-height - $footer-border-top-width - $footer-border-bottom-width;

    top: ($wrapper_height - ($height + $padding * 2)) / 2;
    width: $width;
    height: $height;
    padding: $padding;
    position: absolute;
    box-sizing: content-box;
    z-index: 1000;

    &--left {
      left: $offset;
    }

    &--right {
      right: $offset;
    }
  }

  @media (max-width: 1024px) {
    &__arrow,
    &__button {
      display: none;
    }
  }

  &__name {
    color: $text-color;
    text-align: center;
    font-family: $base-font-family;
    transition: font-size 0.3s;

    @media (orientation: portrait) and (max-width: 1024px) {
      font-size: $font-xm-size;

      .#{$item}.#{$active} & {
        font-size: 46px;
      }
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      .#{$item} & {
        font-size: $font-xs-size;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-xm-size;
      }

      .#{$item}.#{$active} & {
        font-size: 40px;
      }
    }

    @media (min-width: 1025px) {
      .#{$item} & {
        font-size: $font-xxs-size;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-md-size;
      }

      .#{$item}.#{$active} & {
        font-size: 22px;
      }
    }
  }

  &__desc {
    color: gray;
    text-align: center;
    font-family: $base-font-family;
    transition: font-size 0.3s;

    @media (orientation: portrait) and (max-width: 1024px) {
      font-size: $font-sm-size;

      .#{$item}.#{$active} & {
        font-size: $font-xm-size;
      }
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      .#{$item} & {
        font-size: 10px;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-xs-size;
      }

      .#{$item}.#{$active} & {
        font-size: 26px;
      }
    }

    @media (min-width: 1025px) {
      .#{$item} & {
        font-size: $font-xxs-size;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-xxs-size;
      }

      .#{$item}.#{$active} & {
        font-size: $font-xxs-size;
      }
    }
  }
}
