@import "~@/style/global.scss";

.home {
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $m_header-size - 1;
    z-index: 1000;
    background-color: $bg-black-color;
    background-image: radial-gradient(
        ellipse 50% 150% at left,
        fade-out($bg-dark-color, 0.6) 0%,
        $null-color 100%
      ),
      radial-gradient(ellipse 50% 150% at right, fade-out($bg-dark-color, 0.6) 0%, $null-color 100%);
  }
}
