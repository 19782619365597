@import "~@/style/global.scss";

.modal {
  &__textarea {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $btn-primary-color;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $btn-primary-color;
    }

    overflow-y: visible;
    background: none;
    border: none;
    resize: none;
    min-width: 420px;
    color: white;
    font-size: $font-sm-size;
    font-family: $base-font-family, $fallback-font-family;
    width: 100%;
    text-align: left;

    @media screen and (max-width: 760px) {
      min-width: auto;
      text-align: left;
    }
  }
}

:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}

:deep .modal__content {
  max-height: 80%;
  background: none;
}

:deep .modal__window {
  max-width: 700px;
  max-height: 760px;
  padding: 40px;
  // overflow-y: scroll;

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 100%;
  }
}

:deep .modal__header {
  font-style: italic;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.back-button {
  width: 190px;
  height: 40px;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0 auto;

  @media screen and (max-width: 720px) {
    width: 240px;
    margin-top: -26px;
  }
}
