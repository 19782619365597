@import "~@/style/global.scss";

.mobile-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: $m_header-size;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.5s;

  @media (min-width: 1025px) {
    display: none;
  }

  &--variant\:game {
    top: auto;
    bottom: 100%;
    align-items: normal;

    &::v-deep .nav--right {
      top: 100%;
    }
  }

  &__sweepstakes {
    display: flex;
    align-items: center;
    margin: 0 12px;
    font-family: $base-font-family;
    font-size: $font-sm-size;
    color: $text-color;

    &-item + &-item {
      margin-left: 28px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 4px;
    }

    &-title {
      color: $text-color;
      font-family: $base-font-family;
      font-size: $font-xxs-size;
      text-transform: uppercase;
    }

    &-value {
      color: $text-color;
      font-size: $font-xm-size;
      font-family: $base-font-family;
      text-transform: uppercase;
    }
  }

  &__demo,
  &__balance {
    margin: 0 12px;
    font-family: $base-font-family;
    font-size: $font-xm-size;
    color: $text-color;
  }
}
