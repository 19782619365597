@import "~@/style/global.scss";

.buy-time-button {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  cursor: pointer;

  &--mobile {
    width: 100%;
    display: flex;
    align-items: center;
    color: fade-out($text-color, 0.5);
    font-family: $base-font-family;
    text-transform: uppercase;
    box-sizing: border-box;
    position: relative;
    padding: 0 32px;
    cursor: pointer;

    @media (orientation: portrait) {
      font-size: $font-xm-size;
      height: 48px;
    }

    @media (orientation: landscape) {
      font-size: $font-md-size;
      height: 32px;
    }

    &--active {
      color: $text-color;
    }
  }

  &--home {
    span {
      font-weight: 500;
      margin-left: 0;
      padding-right: 2px;
    }
  }

  &--default {
    height: 30px;
    font-size: $font-sm-size;
    text-transform: uppercase;
    margin-left: 2px;
  }

  svg {
    width: 28px;
    margin-top: 8px;
  }

  span {
    margin-left: 2px;
    color: $white-color;
    font-family: $base-font-family;
  }
}
