// The logo on jackpots page

.logo {
  position: absolute;
  top: 5%;

  @media (max-height: 560px) and (orientation: landscape) {
    position: fixed;
    top: 0;
    background-size: 100% auto;
    width: 200px;
    height: 50px;
  }
}
