@import "~@/style/global.scss";

.message {
  color: $text-bright-color;
  font-size: $base-font-size;
  font-weight: bold;
  text-transform: uppercase;
  font-family: $second-font-family;
  padding: 0 2em;
  text-align: center;

  &--onTop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $bg-black-color;
    position: absolute;
    z-index: 15;
  }

  &__icon {
    fill: currentColor;
    display: block;
    margin: 0 auto 1em;
  }

  &__text {
    display: block;
  }
}
