@import "~@/style/global.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bg-black-color;
  background-image: radial-gradient(
      ellipse 150% 50% at top,
      fade-out($bg-dark-color, 0.6) 0%,
      $null-color 100%
    ),
    radial-gradient(ellipse 150% 50% at bottom, fade-out($bg-dark-color, 0.6) 0%, $null-color 100%),
    radial-gradient(ellipse 150% 5% at top, fade-out($bg-contrast-color, 0.75) 0%, $null-color 100%),
    radial-gradient(
      ellipse 150% 5% at bottom,
      fade-out($bg-contrast-color, 0.75) 0%,
      $null-color 100%
    );
  position: fixed;

  &::before,
  &::after {
    content: "";
    left: 0;
    right: 0;
    height: 2px;
    display: block;
    background-image: linear-gradient(
      90deg,
      $null-color 0%,
      $bg-contrast-color 25%,
      $white-color 50%,
      $bg-contrast-color 75%,
      $null-color 100%
    );
    position: absolute;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.logo {
  // TODO fix the logo on jackpots page
  width: 100%;
  height: 160px;
  max-width: 450px;
  background-image: url($path + "logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% auto;
  margin-bottom: -20px;

  @media screen and (min-width: 1025px) {
    height: 256px;
    margin: -50px 0 0;
  }
}

/** Add theme style */
@import "~@theme/style/components/jp-logo.scss";

.frame {
  position: relative;
  width: 1711px;
  height: 496px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-image: url($path + "jackpots__frame.png");
  background-repeat: no-repeat;

  &--jackpot-v2 {
    background-image: url($path + "jackpots_frame_v2.png");
  }
}

.jackpot-page::v-deep .jackpot {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  box-sizing: border-box;

  &:nth-child(1),
  &:nth-child(2) {
    height: 299px;
    flex-direction: column;
    justify-content: center;
  }

  &:nth-child(3),
  &:nth-child(4) {
    height: 196px;
    flex-direction: column;
    padding-bottom: 10px;
  }

  &:nth-child(1) {
    align-items: flex-end;
  }

  &:nth-child(2) {
    align-items: flex-start;
  }

  &:nth-child(3) {
    justify-content: flex-start;
    align-items: flex-end;
  }

  &:nth-child(4) {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &:nth-child(1),
  &:nth-child(3) {
    padding-right: 48px;
  }

  &:nth-child(2),
  &:nth-child(4) {
    padding-left: 48px;
  }

  &__name,
  &__value {
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: bold;
    color: $text-color;
    white-space: nowrap;
  }

  .backgroundcliptext & {
    &__name,
    &__value {
      background: $bg-white-color
        linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:nth-child(1),
  &:nth-child(2) {
    .jackpot__name {
      top: -48px;
      font-size: 120px;
      line-height: $font-line;
      position: absolute;
    }
  }

  &:nth-child(1),
  &:nth-child(2) {
    .jackpot__value {
      font-size: 100px;
    }
  }

  &:nth-child(3),
  &:nth-child(4) {
    .jackpot__name {
      font-size: 70px;
    }
  }

  &:nth-child(3),
  &:nth-child(4) {
    .jackpot__value {
      font-size: 78px;
    }
  }
}
