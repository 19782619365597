// Fonts
$base-font-size: 22px;
$fallback-font-family: sans-serif;

$base-font-family: "Ubuntu", $fallback-font-family;
$second-font-family: "Oswald", $fallback-font-family;

// Weights
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// Size
$font-xxs-size: 12px; // extra small
$font-xs-size: 14px; // extra small
$font-sm-size: 16px; // small
$font-md-size: 18px; // medium
$font-xm-size: 24px; // extra medium
$font-lg-size: 28px; // large
$font-xl-size: 30px; // extra large
$font-xxl-size: 32px; // extra extra large

// Line-height
$font-line: 1;
$font-xs-line: 1.25; // extra small
$font-sm-line: 1.4; // small
$font-md-line: 1.5; // medium
$font-lg-line: 1.6; // large
$font-xl-line: 1.7; // extra large

// Letter-spacing
$font-sm-spacing: -0.015em;
$font-md-spacing: 0.01em;
$font-lg-spacing: 0.03em;
