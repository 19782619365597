@import "~@/style/global.scss";

$size: 64px;
$dragSize: 32px;

$size-sm: 58px;
$dragSize-sm: 28px;

.nav {
  top: 0;
  height: $size;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: absolute;
  transition: background 0.25s ease, transform 0.5s ease;
  overflow: hidden;
  z-index: 110;

  &--translucent {
    background-color: fade-out($bg-black-color, 0.5);
    border-radius: 0 0 5px 5px;

    .nav__btn {
      &--hide {
        border-style: solid;
        border-width: 0 0 0 1px;
        border-color: fade-out($dark-color, 0.5);
      }
    }
  }

  &__btn {
    width: $size;
    color: $white-color;
    fill: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s ease;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 90;

    &--star {
      display: flex;
      justify-content: center;
      align-items: center;
      color: transparent;
      stroke: $white-color;

      &::v-deep svg {
        color: transparent;
      }
    }

    &--star-active {
      &::v-deep svg {
        color: white;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &::v-deep svg {
      width: 50%;
      height: 50%;
    }

    &--hide {
      $offset: $dragSize * 0.2;

      width: $dragSize;
      padding: 0 $offset;
      margin-right: $offset;

      &::v-deep svg {
        opacity: 0.5;
        width: 100%;
      }
    }
  }

  &--compact {
    height: $size-sm;

    .nav__btn {
      width: $size-sm;

      &--hide {
        $offset: $dragSize-sm * 0.2;

        width: $dragSize-sm;
        padding: 0 $offset;
        margin-right: $offset;
      }
    }
  }

  &--hide {
    transform: translateX(calc(-100% + #{$dragSize}));

    .nav__btn--hide {
      transform: scale(1.4);

      &::v-deep svg {
        opacity: 0.8;
      }
    }
  }

  &:not(&--right) {
    left: 0;
    padding-left: 4px;

    .nav__btn {
      &--menu {
        order: -1;
      }

      &--fullscreen {
        order: 0;
      }

      &--back,
      &--sign-out {
        order: 2;
      }

      &--hide {
        order: 99;
      }
    }
  }

  &--right {
    right: 0;
    padding-right: 4px;

    &.nav--hide {
      transform: translateX(calc(100% - #{$dragSize}));
    }

    .nav__btn {
      &--menu {
        order: 99;
      }

      &--back,
      &--sign-out {
        order: 0;
      }

      &--hide {
        border-width: 0 1px 0 0;
        order: -1;
      }
    }
  }

  &--hide:not(.nav--right),
  &--right:not(.nav--hide) {
    .nav__btn--hide::v-deep svg {
      transform: scale(-1, 1);
    }
  }
}
