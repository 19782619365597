@import "~@/style/global.scss";

.modal {
  &__height-wrapper {
    max-height: calc(100% - 50px);
    overflow-y: scroll;
    width: 100%;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 6px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $btn-primary-color;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $btn-primary-color;
    }
  }

  &__grid-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    margin-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    @media (max-width: 960px) and (orientation: portrait) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);

      .back-button {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 960px) and (orientation: landscape) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 12px;
      margin-bottom: 12px;
      padding: 0;

      .back-button {
        width: 180px;
      }
    }
  }

  &__description {
    margin-top: 24px;
    font-size: 16px;
    text-align: center;
  }
}

:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}

:deep .modal__content {
  background: none;
  overflow-y: hidden;
  padding: 5px 0;
  border: none;
  resize: none;
  min-width: 420px;
  color: white;
  font-size: $font-sm-size;
  font-family: $base-font-family, $fallback-font-family;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 760px) {
    min-width: auto;
    text-align: left;
  }
}

:deep .modal__footer {
  padding: 0;
  margin-bottom: 0;

  @media screen and (max-width: 760px) {
    padding: 0;
    margin-bottom: 12px;
  }
}

:deep .modal__window {
  max-width: 700px;
  max-height: 400px;
  padding: 20px;
  //overflow-y: hidden;

  @media screen and (max-width: 960px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    max-height: 80%;
  }

  @media screen and (max-width: 380px) {
    width: 100%;
    max-height: 96%;
  }

  @media screen and (max-height: 480px) {
    width: 100%;
    max-height: 100%;
  }
}

:deep .modal__header {
  font-style: italic;
}

:deep .modal__content {
  align-items: flex-start;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 960px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.back-button {
  width: 200px;
  height: 40px;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0 auto;

  @media (max-width: 1600px) {
    width: 200px;
  }

  @media (max-width: 1600px) and (max-height: 800px) {
    width: 140px;
  }

  @media (max-width: 1400px) {
    width: 160px;
  }

  @media (max-width: 768px) {
    width: 140px;
  }

  @media (max-width: 960px) and (orientation: landscape) {
    width: 180px;
  }
}
