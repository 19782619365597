@import "~@/style/global.scss";

.keyboard {
  display: flex;
  perspective: 1000px;
  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1025px) {
    width: 298px;
    height: 510px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &__key {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 88px;
    height: 88px;
    background-image: url($path + "key-desktop.png");
    background-repeat: no-repeat;
    background-size: contain;

    font-family: $second-font-family;
    font-weight: bold;
    font-size: 44px;
    color: $text-color;

    user-select: none;
    cursor: pointer;

    & + & {
      margin-left: 16px;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &:nth-last-child(1) {
      margin-left: 0;
    }

    &--enter {
      width: 194px;
      background-image: url($path + "enter-key-desktop.png");
      background-color: transparent;

      &:nth-last-child(1) {
        margin-left: 16px;
      }
    }

    &--shift {
      color: $text-main-color;
    }

    &--upperCase {
      text-transform: uppercase;
    }
  }

  &--alphabet {
    width: 1144px;
    margin-left: -16px;

    .keyboard__wrapper {
      transform: rotateY(180deg) scaleX(-1);
      margin: auto 0;
    }

    .keyboard__key {
      &:nth-child(n) {
        margin-left: 16px;
      }

      &:nth-child(21) {
        margin-left: 14px;
      }

      &:nth-last-child(1) {
        margin-left: 14px;
      }
    }
  }
}
