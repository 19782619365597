@import "~@/style/global.scss";

.home {
  width: 100%;
  height: 100%;

  &__wheel-fortune-wrapper {
    position: relative;
    z-index: 1001;
    padding-top: 2px;
    top: -30px;
    display: flex;
    justify-content: center;
    width: 100%;

    &--no-jackpots {
      top: 58px;
    }

    @media (max-width: 1024px) {
      position: fixed;
      top: 48px;
      z-index: 4;

      &--jackpots-hidden {
        display: none;
      }
    }
  }

  @media (min-width: 1025px) {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    @media (min-width: 1025px) {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
    }
  }

  &__section {
    &--jackpots {
      @media (min-width: 1025px) {
        position: relative;
      }
    }

    &--games {
      overflow: hidden;

      @media (min-width: 1025px) {
        margin-top: 4px;
        flex: 1;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

/** Swiper */
.swiper {
  &-container-vertical > &-pagination-bullets {
    $bullet: swiper-pagination-bullet;

    .#{$bullet} {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .#{$bullet} + .#{$bullet} {
      margin: ($m_swiper-p-size * 0.75) 0 0 0;
    }
  }

  &-pagination {
    right: $m_swiper-p-indent !important;
    padding: ($m_swiper-p-size / 3) ($m_swiper-p-size / 2.5) ($m_swiper-p-size / 3) 0;
    -webkit-tap-highlight-color: $null-color;

    &-bullet {
      padding: 0;
      width: $m_swiper-p-size * 2;
      height: $m_swiper-p-size * 2;
      background-color: $null-color;
      cursor: pointer;
      opacity: 1;

      &:after {
        content: "";
        flex: 0 0 $m_swiper-p-size;
        width: $m_swiper-p-size;
        height: $m_swiper-p-size;
        display: block;
        transition: box-shadow 0.4s, background 0.75s;
        background-color: fade-out($bg-white-color, 0.5);
        border-radius: 50%;
      }

      &-active {
        background-color: $null-color;

        &:after {
          background-color: fade-out($bg-white-color, 0.25);
          box-shadow: 0 0 0 $m_swiper-p-active fade-out($bg-white-color, 0.75);
        }
      }
    }
  }
}
