@import "~@/style/global.scss";

.sign-in {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: $bg-black-color;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10001;
  }

  &__input {
    color: $text-color;
    font-family: $base-font-family;
    background-color: transparent;
    border-image: linear-gradient(to bottom, #444546 0, #ffffff 50%, #444546 100%);
    border-image-slice: 1;
    border-width: 2px;
    box-sizing: border-box;

    &::-webkit-input-placeholder {
      color: $text-color;
    }

    &:-moz-placeholder {
      color: $text-color;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: $text-color;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $text-color;
    }

    &::-ms-input-placeholder {
      color: $text-color;
    }

    &::placeholder {
      color: $text-color;
    }

    &:focus-visible {
      outline: $bg-main-color auto 1px;
    }

    @media (max-width: 1024px) {
      font-size: $base-font-size;
      margin: 0 50px;
      padding: 0 16px;
      width: calc(100% - 100px);
      max-width: 318px;
      height: 54px;

      & + & {
        margin-top: 16px;
      }
    }

    @media (min-width: 1025px) {
      font-size: $font-md-size;
      padding: 0 16px;
      width: 296px;
      height: 56px;

      & + & {
        margin-top: 24px;
      }
    }
  }

  &__login-button {
    color: $text-color;
    font-family: $second-font-family;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    @media (max-width: 1024px) {
      height: 70px;
      min-width: 154px;
      font-size: 38px;
      margin-top: 24px;
    }

    @media (min-width: 1025px) {
      height: 88px;
      min-width: 194px;
      font-size: 48px;
      margin-top: 40px;
    }
  }

  &__error-message {
    $offset: 24px;

    color: lighten($tomato-color, 10%);
    font-family: $base-font-family;
    text-align: center;
    position: relative;
    margin-top: $offset * 2;

    &:before {
      content: "";
      top: -$offset * 0.8;
      left: 50%;
      width: 100%;
      height: 2px;
      display: block;
      background-color: rgba(72, 72, 72, 0.25);
      transform: translateX(-50%);
      position: absolute;

      @media (max-width: 1024px) {
        width: calc(100% - 48px);
        max-width: 318px;
      }

      @media (min-width: 1025px) {
        width: 296px;
      }
    }
  }

  &__back {
    color: $white-color;
    font-size: $font-xxl-size;
    font-family: $base-font-family;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    position: fixed;
    cursor: pointer;
    bottom: 0;
    z-index: 999;

    @media (max-width: 580px) {
      bottom: 120px;
    }
  }

  &__footer {
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;

    @media (max-width: 1024px) {
      height: 54px + 18px * 2;
    }

    @media (max-width: 1024px) and (orientation: portrait) {
      padding: 0 18px;
    }

    @media (max-width: 1024px) and (orientation: landscape) {
      padding: 0 16px;
    }

    @media (min-width: 1025px) {
      height: 36px;
      border-top: 1px solid $border-gray-color;
      padding: 0 26px;
    }

    &-button {
      font-family: $base-font-family;
      text-transform: uppercase;
      padding: 7px 18px;

      & + & {
        margin-left: 2px;
      }

      @media (max-width: 1024px) {
        font-size: $font-lg-size;
      }

      @media (min-width: 1025px) {
        font-size: $font-sm-size;
      }

      @media (max-width: 468px) {
        font-size: $font-xs-size;
      }
    }
  }

  &__logo {
    top: 20%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url($path + "logo.png");
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    transform: translate(-50%, -50%) scale(0.37, 0.3);
    position: absolute;
    z-index: 100000;

    @media screen and (orientation: landscape) and (max-width: 1024px) {
      display: none;
    }

    @media (max-width: 1024px) {
      transform: translate(-50%, -62%) scale(0.32, 0.32);
      padding: 0 250px;
    }
  }

  &__logo-bottom {
    width: 100%;
    height: 100%;
    max-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    top: 70%;
    padding: 0 250px;
    position: absolute;
    z-index: 100000;

    @media screen and (orientation: landscape) and (max-width: 1024px) {
      display: none;
    }
  }
}
