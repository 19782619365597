@import "~@/style/global.scss";

.loading {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: $bg-black-color;
  z-index: 999999;
}

.loading:before {
  content: "";
  top: 50%;
  left: 50%;
  width: 180px;
  height: 180px;
  display: block;
  position: absolute;
  background-image: url($path + "loading.png");
  background-repeat: no-repeat;
  animation: spin 3s linear infinite;
  margin-top: -90px;
  margin-left: -90px;
}

.loading--semitransparent {
  background-color: fade-out($bg-black-color, 0.2);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
