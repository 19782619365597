@import "~@/style/global.scss";

.games {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;

  &__button-back {
    margin-top: auto;
    margin-bottom: 50px;
    color: $text-color;
    font-family: $second-font-family;
    font-size: $font-sm-size;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 190px;
    height: 40px;

    @media screen and (max-width: 720px) {
      width: 240px;
      padding: 20px;
    }
  }

  &__button-back-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &--favorite-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85%;
    margin: 0 10%;
    color: #f1f1f1;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    padding: 0 2em;
    text-align: center;
  }

  &--first-row {
    font-size: 22px;
    font-weight: bold;
  }

  &--recommendations {
    color: #f1f1f1;
    font-size: 16px;
    margin-top: 100px;
    margin-bottom: -125px;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    padding: 0 2em;
    text-align: center;

    @media (max-width: 1025px) {
      margin-top: 6px;
      padding: 0 8px;
      font-size: 12px;
      margin-bottom: -8px;
    }
  }

  &--recommendations-grid {
    @media (min-width: 1025px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .game {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 1024px) {
    height: calc(100% - 64px);
    margin: 64px 0 0 0;

    mask-image: linear-gradient(
      to right,
      fade-out($bg-black-color, 0.75) 0%,
      $bg-black-color 25%,
      $bg-black-color 75%,
      fade-out($bg-black-color, 0.75) 100%
    );
  }

  @media (min-width: 1025px) {
    height: 100%;
    padding: 0 80px;

    &:before {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      display: block;
      background-image: linear-gradient(
        to right,
        $null-color 0,
        $bg-white-color 50%,
        $null-color 100%
      );
      position: absolute;
    }
  }

  &__arrow {
    $width: 97px;
    $height: 95px;

    top: 50%;
    width: $width;
    height: $height;
    position: absolute;
    margin-top: (-$height / 2);

    &--prev {
      left: 40px;
    }

    &--next {
      right: 40px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;

    @media (min-width: 1025px) {
      background-image: radial-gradient(
          50% 50% ellipse at top,
          fade-out($bg-dark-color, 0.5),
          $null-color 100%
        ),
        radial-gradient(50% 50% ellipse at bottom, fade-out($bg-dark-color, 0.5), $null-color 100%);

      mask-image: linear-gradient(
        to right,
        $null-color 0,
        $null-color 40px,
        $bg-black-color 100px,
        $bg-black-color calc(100% - 100px),
        $null-color calc(100% - 40px),
        $null-color 100%
      );
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__scroller {
    padding: 0 60px;
    width: 100%;
    height: 100%;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    box-sizing: border-box;
    position: absolute;

    &--recommendations {
      display: none;
    }

    //  left: 50%;
    //
    //  @media (max-width: 1800px) {
    //    transform: translateX(-25%);
    //  }
    //
    //  @media (max-width: 1450px) {
    //    transform: translateX(-35%);
    //  }
    //
    //  @media (max-width: 1340px) {
    //    transform: translateX(-36%);
    //  }
    //
    //  @media (max-width: 1250px) {
    //    transform: translateX(-40%);
    //  }
    //
    //  @media (max-width: 1166px) {
    //    transform: translateX(-45%);
    //  }
    //
    //  @media (max-width: 1025px) {
    //    left: inherit;
    //    transform: inherit;
    //  }
    //}
  }

  &__scroller::-webkit-scrollbar {
    height: 4px;

    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0, transparent),
      color-stop(50%, #fff),
      to(transparent)
    );
    background-image: linear-gradient(90deg, transparent 0, #fff 50%, transparent);
  }

  &__scroller::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &__scroller::-webkit-scrollbar-thumb {
    background-color: $bg-main-color;
  }

  .games__column {
    padding: 0 8px;
    display: flex;
    flex-direction: column;

    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    will-change: opacity;
  }

  .games__column--visible {
    opacity: 1;
  }

  .games__column .game + .game {
    margin-top: 8px;
  }

  .fake-game {
    position: absolute;
    visibility: hidden;
  }
}
