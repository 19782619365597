@import "~@/style/global.scss";

.custom-select {
  position: relative;
  min-width: 150px;
  margin-right: 2px;

  .vs__selected {
    color: white;
    z-index: 15;
    margin: 0 0 4px 0;
    .ico-flag {
      margin-bottom: 0;
    }
  }

  //.vs__clear {
  //  display: none;
  //}

  .v-select {
    &:after {
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      pointer-events: none;
      background-position-y: 0;
      content: "";
      background-image: url("../../assets/images/btn_effects.png");
      background-repeat: no-repeat;
      background-size: 100% 200%;
      z-index: 5;
    }
  }

  .vs__dropdown-toggle {
    border-style: solid;
    border-image-slice: 1;
    border-radius: 1px;
    border-color: $null-color;
    border-width: 1px;
    max-height: 30px;
    background-color: $btn-primary-color;
    border-image-source: linear-gradient(
      to bottom,
      fade-out($border-primary-color, 0.5),
      $border-primary2-color,
      fade-out($border-primary-color, 0.5)
    );
    max-height: 30px;
  }

  .vs__open-indicator {
    fill: white;
    z-index: 15;
  }

  * {
    font-family: $base-font-family;
    font-size: $font-md-size;
  }

  .custom-select-flag {
    .ico-flag {
      margin-bottom: -2px;
    }
    &--he {
      margin-left: 4px;
      margin-top: 4px;
    }
  }
}
