@import "~@/style/global.scss";

.win-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    width: 200px;
    height: 100px;
    top: -80px;
    content: "";
    background-image: url("../assets/images/ribbon-bow.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  @media screen and (max-width: 1024px) and (orientation: landscape) {
    &:before {
      content: none;
    }
  }

  &__text {
    font-family: $base-font-family;
    font-style: normal;
    font-size: 36px;
    line-height: 41px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #feef66;
    text-shadow: 0 0 14px #feef66;
  }

  &__button {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 14px;
    cursor: pointer;
    width: 160px;
    height: 40px;
    color: white;
    border: 1px solid #feef66;
    filter: drop-shadow(0px 0px 14px #ec9f15);
    border-radius: 6px;

    &:hover {
      opacity: 0.9;
    }
  }
}
:deep .modal__footer {
  padding: 0;
  margin-bottom: 0;

  @media screen and (max-width: 760px) {
    padding: 0;
    margin-bottom: 12px;
  }
}

:deep .modal__window {
  position: relative;
  max-width: 465px;
  max-height: 390px;
  padding: 40px;
  background: linear-gradient(275.75deg, #8c0304 0%, #eb2d4b 99.14%);
  border: 1px solid #feef66;
  box-shadow: 0px 0px 14px #ec9f15;
  border-radius: 6px;

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 100%;
  }
}

:deep .modal__header {
  font-style: italic;
}

:deep .modal__content {
  align-items: flex-start;
  background-image: none;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.back-button {
  width: 190px;
  height: 40px;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0 auto;
  animation: blue-neon 2s linear infinite;

  @media screen and (max-width: 720px) {
    width: 240px;
    margin-top: -26px;
  }
}
