@import "~@/style/global.scss";

.wrapper {
  width: 100%;
  height: max-content;

  @media screen and (max-width: 480px) {
    width: max-content;
  }
}

.flex-table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 4px !important;
    background: $btn-primary-color;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px !important;
    background: $btn-primary-color;
  }

  &::-webkit-scrollbar {
    width: 2px !important;
    height: 4px !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $btn-primary-color;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
}

.flex-header {
  background-color: rgba(174, 166, 166, 0.47);
  font-weight: bold;
  padding: 8px;
  flex: 1;
  text-align: center;
}

.flex-cell {
  padding: 8px;
  flex: 1;
  text-align: center;
}

.bonus-table {
  margin-top: 32px;
}

.bonus-title {
  margin: 4px 0;
}
