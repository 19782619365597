@import "~@/style/global.scss";

$close_size: 32px;
$close_offsetY: 17px;
$close_offsetX: 20px;

.tag {
  &--aviator {
    width: 55px;
    height: 20px;
    margin-left: 20px;

    svg {
      transform: scaleX(2) scaleY(2);
      width: 100%;
      height: 100%;
      color: #e50539;
    }
  }

  &--christmas {
    width: 80px;
    height: 30px;
    margin-left: 12px;

    svg {
      transform: scaleX(1.5) scaleY(1.5);
      width: 100%;
      height: 100%;
      color: #42ff00;
    }
  }

  &--halloween {
    width: 58px;
    height: 20px;
    margin-left: 20px;

    svg {
      transform: scaleX(2) scaleY(2);
      width: 100%;
      height: 100%;
      color: #ff7a00;
    }
  }

  &--chinese {
    width: 120px;
    height: 20px;
    margin-left: 35px;

    svg {
      transform: scaleX(1.8) scaleY(1.8);
      width: 100%;
      height: 100%;
      color: #efb717;
    }
  }

  @media screen and (min-width: 970px) {
    &--aviator {
      svg {
        margin-left: -10px;
        transform: scaleX(1.4) scaleY(1.4);
        color: #e50539;
      }
    }
  }

  &--img-tag-active {
    svg {
      color: white;
    }
  }
}

.menu {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.3s;
  position: fixed !important;
  z-index: 999;

  &:not(&--showed) {
    transform: translateX(100%);

    &.menu--left {
      transform: translateX(-100%);
    }
  }

  &__content {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 512px;
    background-color: fade-out($bg-black-color, 0.1);
    position: relative;
    padding-top: 10px;
    margin-left: auto;
    overflow-y: scroll;

    @media (max-height: 430px) and (orientation: landscape) {
      height: 95%;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 60px;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    & & {
      padding: 0 0 8px 30px;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    color: fade-out($text-color, 0.5);
    font-family: $base-font-family;
    text-transform: uppercase;
    box-sizing: border-box;
    position: relative;
    padding: 0 32px;
    cursor: pointer;

    @media (orientation: portrait) {
      font-size: $font-xm-size;
      height: 48px;
    }

    @media (orientation: landscape) {
      font-size: $font-md-size;
      height: 32px;
    }

    &--btn-sign-out {
      margin-left: auto;
      fill: white;
    }

    &--active {
      color: $text-color;
    }

    &--line {
      border-top: 1px solid white;
      height: 2px;
      width: 86%;
    }
  }

  &__btn--close {
    top: $close_offsetY;
    right: $close_offsetX;
    width: $close_size;
    height: $close_size;
    background-image: url($path + "hide-menu-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
  }

  &--left {
    .menu {
      &__content {
        margin-left: 0;
      }

      &__wrapper {
        padding-left: 120px;
      }

      &__btn--close {
        left: $close_offsetX;
        right: auto;
      }
    }
  }
}
