@import "~@/style/global.scss";

:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}
:deep .modal__content {
  background: none;
  overflow-y: hidden;
  padding: 5px 0;
  border: none;
  resize: none;
  min-width: 420px;
  color: white;
  font-size: $font-sm-size;
  font-family: $base-font-family, $fallback-font-family;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 760px) {
    min-width: auto;
    text-align: left;
  }
}
:deep .modal__footer {
  padding: 0;
  margin-bottom: 0;

  @media screen and (max-width: 760px) {
    padding: 0;
    margin-bottom: 12px;
  }
}
:deep .modal__window {
  max-width: 700px;
  max-height: 800px;
  padding: 40px;
  overflow-y: hidden;
  position: relative;

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 100%;
  }
}
:deep .modal__header {
  font-style: italic;
  font-size: 40px;
  margin-top: -22px;
  margin-left: -24px;

  @media screen and (max-width: 1024px) {
    margin: 4px 8px;
    display: flex;
    justify-content: center;
    font-size: 28px;
  }
}

:deep .modal__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.mt-20 {
  margin-top: 40px;

  @media screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 10px !important;
  }
}

.w-full {
  width: 100%;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 25px;

  @media screen and (max-width: 768px) {
    right: 2px;
  }
}

.list {
  position: relative;
  padding-top: 20px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-color: fade-out($bg-main-color, 0.2) white;
  scrollbar-width: thin;

  .margin-16 {
    padding: 0 16px;
  }
}
