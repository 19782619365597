@import "~@/style/global.scss";

* {
  font-family: $base-font-family;
}

.hexagon-wrapper {
  position: relative;
  width: 100%;

  &__gradient-line {
    &::before {
      content: "";
      position: absolute;
      top: 43%;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        to right,
        rgba(51, 51, 51, 0) 0%,
        rgb(127, 145, 200),
        rgba(51, 51, 51, 0) 100%
      );
    }
  }
}

.hexagon {
  position: relative;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 34px;
  min-width: 120px;
  padding: 12px 24px;
  margin: 12px 0 4px 0;
  float: left;
  font-weight: bold;
  text-align: center;

  &--timer-expired {
    cursor: pointer;
  }

  &__mini-wheel-svg {
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 71%) scale(1.1);

    &--active {
      //box-shadow: 1px 1px 1px 1px #0b5412;
    }
  }

  &__button {
    cursor: pointer;
    position: relative;
    background-image: url($path + "get-bonus-btn.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 138px;
    height: 38px;
    top: -6px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 50%);
  }

  &__button-title {
    position: absolute;
    top: 50%;
    left: 35%;
    width: 100%;
    word-break: break-word;
    inline-size: 100px;
    text-wrap: balance;
    font-family: $second-font-family;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    @include adaptive-font(14, 12);
    color: white;
    transform: translate(-28%, -50%);
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.65);
  }

  svg {
    position: absolute;
    height: 70%;
    width: 90%;
    top: 0px;
    left: 5px;
    z-index: -1;
  }

  &__border {
    stroke: url(#brdgrad);
    stroke-width: 2;
  }

  &__text {
    display: block;
    margin-top: 8px;
    padding: 8px;
    transform: translateY(-50%);
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: bold;
    color: $text-color;
    white-space: nowrap;
  }
}
