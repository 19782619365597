.logo {
  background-size: 100% auto;
  transform: translate(-50%, -50%);

  &--wide {
    background-size: auto 60%;

    @media (orientation: portrait) and (max-width: 1080px) {
      background-size: 80% auto;
    }

    @media (orientation: landscape) and (max-height: 511px) {
      background-size: 80% auto;
      max-height: 140px;
      top: 55% !important;
    }
  }

  &--full {
    background-size: 95% auto;

    @media (orientation: landscape) {
      background-size: 85% auto;
    }
  }

  @media (max-width: 1080px) {
    &:not(&--wide) {
      width: 15%;
    }
  }

  @media (max-width: 1150px) {
    &:not(&--wide) {
      width: 19%;
    }
  }

  @media (min-width: 1025px) {
    height: $header-size - $header-margin - 1px;
  }
}
