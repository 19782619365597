@import "~@/style/global.scss";

.session {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-black-color;
  position: relative;
  overflow: hidden;

  &--sweepstake {
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      margin-bottom: 40px;
    }
  }

  &__frame {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 10;
    scrollbar-color: #6969dd #e0e0e0;
    scrollbar-width: thin;

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }
  }

  /** Fixed */

  &--scaled &__frame {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: 0 0;
  }

  &__error-steps {
    margin-top: 50px;
    text-align: center;
    font-size: $font-md-size;
    padding: 0 2em;

    &--big-font {
      font-size: 22px;
    }
  }

  &__button-game {
    margin-top: 50px;
    color: $text-color;
    font-family: $second-font-family;
    font-size: $font-md-size;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 180px;
    height: 40px;
  }

  &__code {
    background-color: white;
    color: black;
    font-size: $font-sm-size;
    border-radius: 4px;
    padding: 5px;
  }

  &__code-wrapper {
    margin-top: 4px;
    margin-bottom: 16px;
  }

  &__button {
    position: relative;
    bottom: -20%;
    color: $text-color;
    font-family: $second-font-family;
    cursor: pointer;
    z-index: 15;
    text-transform: uppercase;
    font-size: $font-sm-size;
    width: 190px;
    height: 45px;
  }
}
