@import "~@/style/global.scss";
body,
html {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  touch-action: pan-x pan-y; // disable zoom mobile for safari
}

html {
  line-height: 1.15;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;

  /* iOS viewport bug fix */
  @supports (-webkit-touch-callout: none) {
    &,
    body {
      height: 100vh; // or calc(var(--vh, 1vh) * 100);
    }

    @media (max-width: 1024px) {
      height: -webkit-fill-available;

      body {
        min-height: 100vh;
        min-height: -moz-available;
        min-height: -webkit-fill-available;
      }
    }
  }
}

.modal-sport-bets-history {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }
}

.vs__dropdown-menu {
  border-width: 1px;
  background-color: $btn-primary-color;
  scrollbar-color: fade-out($bg-main-color, 0.2) white;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
    background: $btn-primary-color;
  }

  ::-webkit-scrollbar:horizontal {
    height: 12px;
    background: $btn-primary-color;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background: $btn-primary-color;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $btn-primary-color;
  }

  @media screen and (max-width: 1024px) and (orientation: landscape) {
    max-height: 65dvh;
  }

  .vs__dropdown-option {
    font-family: $base-font-family;
    font-size: $font-md-size;
    color: white;
    background: none;
  }

  .vs__dropdown-option--highlight {
    &:hover,
    &:focus,
    &:checked,
    &:active {
      background: lighten($btn-primary-color, 10%);
      background: radial-gradient(
        circle,
        lighten($btn-primary-color, 15%) 0,
        $btn-primary-color 100%
      );
    }
  }
}

.coupon {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: 16px;
  margin-right: 16px;
  overflow: hidden;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  & + & {
    margin-top: 1.25em;
  }
}
.tab-content {
  max-height: 80%;
  padding-bottom: 100px;

  @media screen and (max-height: 768px) and (orientation: landscape) {
    max-height: 60%;
  }
}

.coupon .coupon-status_win {
  position: relative;

  &::before {
    content: "";
    border-right: 0.375em solid #45a604;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.coupon .coupon-status_lost,
.coupon .coupon-status_lose {
  position: relative;

  &::before {
    content: "";
    border-right: 0.375em solid #f31414;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.coupon .tl {
  grid-area: tl;
}
.coupon .tr {
  grid-area: tr;
  text-align: right;
}
.coupon .bl {
  grid-area: bl;
}
.coupon .br {
  grid-area: br;
  text-align: right;
}

.coupon-footer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto minmax(1.25em, 1fr);
  grid-template-areas:
    "tl tr"
    "bl br";
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

body {
  background-color: $bg-black-color;
  margin: 0;

  @media (orientation: portrait) and (max-width: 1024px) {
    background-image: radial-gradient(
        ellipse 50% 150% at left,
        fade-out($bg-dark-color, 0.6) 0%,
        $null-color 100%
      ),
      radial-gradient(ellipse 50% 150% at right, fade-out($bg-dark-color, 0.6) 0%, $null-color 100%);
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    background-image: radial-gradient(
        ellipse 150% 5% at top,
        fade-out($bg-contrast-color, 0.75) 0%,
        $null-color 100%
      ),
      radial-gradient(
        ellipse 150% 5% at bottom,
        fade-out($bg-contrast-color, 0.75) 0%,
        $null-color 100%
      ),
      radial-gradient(ellipse 150% 50% at top, fade-out($bg-dark-color, 0.6) 0%, $null-color 100%),
      radial-gradient(
        ellipse 150% 50% at bottom,
        fade-out($bg-dark-color, 0.6) 0%,
        $null-color 100%
      );
  }
}

.visually-hidden {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  position: absolute;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: -1000;
}

.uppercase {
  text-transform: uppercase;
}

.mobile-hidden {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.ico-flag {
  width: 18px;
  height: 12px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  background-image: url(../assets/images/flags18x12.png);
  background-repeat: no-repeat;
  background-position: -999px 0;
  margin: 0px 6px 0 0;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi) {
    background-size: 18px 2988px;
    background-image: url(../assets/images/flags36x24.png);
  }
}

.ico {
  &-eye {
    &:after {
      content: "\E02B";
    }

    &-slash:after {
      content: "\E02A";
    }
  }

  &-close:after {
    content: "\E01C";
  }

  &-gear:after {
    content: "\E039";
  }

  &-refresh:after {
    content: "\E055";
  }

  &-resize {
    &-in:after {
      content: "\E057";
    }

    &-out:after {
      content: "\E058";
    }
  }

  &-arrow {
    &-top:after {
      content: "\E005";
    }

    &-back:after {
      content: "\E001";
    }

    //&-down:after {
    //  content: "\E002";
    //}

    //&-left:after {
    //  content: "\E003";
    //}

    //&-right:after {
    //  content: "\E004";
    //}

    //&-up {
    //  &-circle:after {
    //    content: "\E006";
    //  }
    //
    //  &:after {
    //    content: "\E007";
    //  }
    //}
  }

  &-b-arrow {
    //  &-back:after {
    //    content: "\E008";
    //  }
    //
    &-down:after {
      content: "\E009";
    }
    //
    //  &-left:after {
    //    content: "\E00A";
    //  }
    //
    //  &-right:after {
    //    content: "\E00B";
    //  }
    //
    //  &-up:after {
    //    content: "\E00C";
    //  }
  }

  //&-ban:after {
  //  content: "\E00D";
  //}

  //&-bars {
  //  &:after {
  //    content: "\E00F";
  //  }
  //
  //  &-thin:after {
  //    content: "\E00E";
  //  }
  //}

  //&-bell:after {
  //  content: "\E010";
  //}

  //&-calc {
  //  &:after {
  //    content: "\E012";
  //  }
  //
  //  &-slash:after {
  //    content: "\E011";
  //  }
  //}

  //&-card-missed:after {
  //  content: "\E013";
  //}

  //&-card-rotate:after {
  //  content: "\E014";
  //}

  //&-caret-down:after {
  //  content: "\E015";
  //}

  //&-caret-up:after {
  //  content: "\E016";
  //}

  //&-cash:after {
  //  content: "\E017";
  //}

  //&-chart:after {
  //  content: "\E018";
  //}

  //&-check:after {
  //  content: "\E019";
  //}

  //&-circle:after {
  //  content: "\E01A";
  //}

  //&-clock-sp2:after {
  //  content: "\E01B";
  //}

  //&-cloudy:after {
  //  content: "\E01D";
  //}

  //&-clubs:after {
  //  content: "\E01E";
  //}

  //&-coins:after {
  //  content: "\E01F";
  //}

  //&-cold:after {
  //  content: "\E020";
  //}

  //&-comm:after {
  //  content: "\E021";
  //}

  //&-date:after {
  //  content: "\E022";
  //}

  //&-del:after {
  //  content: "\E023";
  //}

  //&-diamonds:after {
  //  content: "\E024";
  //}

  //&-earth:after {
  //  content: "\E025";
  //}

  //&-edit:after {
  //  content: "\E026";
  //}

  //&-exchange-cash:after {
  //  content: "\E027";
  //}

  //&-exchange:after {
  //  content: "\E028";
  //}

  //&-exclamation-circle:after {
  //  content: "\E029";
  //}

  //&-field {
  //  &-sp1-o:after {
  //    content: "\E02C";
  //  }
  //
  //  &-sp1:after {
  //    content: "\E02D";
  //  }
  //
  //  &-sp2-o:after {
  //    content: "\E02E";
  //  }
  //
  //  &-sp2:after {
  //    content: "\E02F";
  //  }
  //
  //  &-sp23-o:after {
  //    content: "\E030";
  //  }
  //
  //  &-sp23:after {
  //    content: "\E031";
  //  }
  //
  //  &-sp4-o:after {
  //    content: "\E032";
  //  }
  //
  //  &-sp4:after {
  //    content: "\E033";
  //  }
  //
  //  &-sp5-o:after {
  //    content: "\E034";
  //  }
  //
  //  &-sp5:after {
  //    content: "\E035";
  //  }
  //}

  //&-file {
  //  &:after {
  //    content: "\E038";
  //  }
  //
  //  &-list:after {
  //    content: "\E037";
  //
  //    &-copy:after {
  //      content: "\E036";
  //    }
  //  }
  //}

  //&-good:after {
  //  content: "\E03A";
  //}

  //&-grid-one:after {
  //  content: "\E03B";
  //}

  //&-grid-three:after {
  //  content: "\E03C";
  //}

  //&-grid-two:after {
  //  content: "\E03D";
  //}

  //&-hand-sp2:after {
  //  content: "\E03E";
  //}

  //&-heart:after {
  //  content: "\E03F";
  //}

  //&-heavyrain:after {
  //  content: "\E040";
  //}

  //&-heavysnow:after {
  //  content: "\E041";
  //}

  //&-highwind:after {
  //  content: "\E042";
  //}

  //&-hot:after {
  //  content: "\E043";
  //}

  //&-incog:after {
  //  content: "\E044";
  //}

  //&-info:after {
  //  content: "\E045";
  //}

  //&-lightsnow:after {
  //  content: "\E046";
  //}

  //&-like:after {
  //  content: "\E047";
  //}

  //&-list-lg:after {
  //  content: "\E048";
  //}

  //&-list:after {
  //  content: "\E049";
  //}

  //&-lock:after {
  //  content: "\E04A";
  //}

  //&-medal:after {
  //  content: "\E04B";
  //}

  //&-note:after {
  //  content: "\E04C";
  //}

  //&-pentagon:after {
  //  content: "\E04D";
  //}

  //&-pin {
  //  &:after {
  //    content: "\E050";
  //  }
  //
  //  &-on:after {
  //    content: "\E04F";
  //  }
  //
  //  &-off:after {
  //    content: "\E04E";
  //  }
  //}

  //&-priority:after {
  //  content: "\E051";
  //}

  //&-rainy:after {
  //  content: "\E052";
  //}

  //&-random {
  //  &:after {
  //    content: "\E054";
  //  }
  //
  //  &-plus:after {
  //    content: "\E053";
  //  }
  //}

  //&-remove:after {
  //  content: "\E056";
  //}

  //&-screen-lock:after {
  //  content: "\E059";
  //}

  //&-search:after {
  //  content: "\E05A";
  //}

  //&-shirt:after {
  //  content: "\E05B";
  //}

  //&-simple-flag:after {
  //  content: "\E05C";
  //}

  //&-sleet-hail:after {
  //  content: "\E05D";
  //}

  //&-smile-sad:after {
  //  content: "\E05E";
  //}

  //&-snowy:after {
  //  content: "\E05F";
  //}

  //&-sort-desc:after {
  //  content: "\E060";
  //}

  //&-spades:after {
  //  content: "\E061";
  //}

  //&-sp {
  //  &_1:after {
  //    content: "\E062";
  //  }
  //
  //  &_1000:after {
  //    content: "\E063";
  //  }
  //
  //  &_1001:after {
  //    content: "\E064";
  //  }
  //
  //  &_1002:after {
  //    content: "\E065";
  //  }
  //
  //  &_1003:after {
  //    content: "\E066";
  //  }
  //
  //  &_1004:after {
  //    content: "\E067";
  //  }
  //
  //  &_1005:after {
  //    content: "\E068";
  //  }
  //
  //  &_1006:after {
  //    content: "\E069";
  //  }
  //
  //  &_1007:after {
  //    content: "\E06A";
  //  }
  //
  //  &_1008:after {
  //    content: "\E06B";
  //  }
  //
  //  &_1009:after {
  //    content: "\E06C";
  //  }
  //
  //  &_11:after {
  //    content: "\E06D";
  //  }
  //
  //  &_12:after {
  //    content: "\E06E";
  //  }
  //
  //  &_13:after {
  //    content: "\E06F";
  //  }
  //
  //  &_14:after {
  //    content: "\E070";
  //  }
  //
  //  &_15:after {
  //    content: "\E071";
  //  }
  //
  //  &_16:after {
  //    content: "\E072";
  //  }
  //
  //  &_17:after {
  //    content: "\E073";
  //  }
  //
  //  &_19:after {
  //    content: "\E074";
  //  }
  //
  //  &_2:after {
  //    content: "\E075";
  //  }
  //
  //  &_22:after {
  //    content: "\E076";
  //  }
  //
  //  &_23:after {
  //    content: "\E077";
  //  }
  //
  //  &_24:after {
  //    content: "\E078";
  //  }
  //
  //  &_26:after {
  //    content: "\E079";
  //  }
  //
  //  &_28:after {
  //    content: "\E07A";
  //  }
  //
  //  &_29:after {
  //    content: "\E07B";
  //  }
  //
  //  &_3:after {
  //    content: "\E07C";
  //  }
  //
  //  &_30:after {
  //    content: "\E07D";
  //  }
  //
  //  &_31:after {
  //    content: "\E07E";
  //  }
  //
  //  &_34:after {
  //    content: "\E07F";
  //  }
  //
  //  &_4:after {
  //    content: "\E080";
  //  }
  //
  //  &_5:after {
  //    content: "\E081";
  //  }
  //
  //  &_6:after {
  //    content: "\E082";
  //  }
  //
  //  &_60:after {
  //    content: "\E083";
  //  }
  //
  //  &_61:after {
  //    content: "\E084";
  //  }
  //
  //  &_7:after {
  //    content: "\E085";
  //  }
  //
  //  &_9:after {
  //    content: "\E086";
  //  }
  //
  //  &_all:after {
  //    content: "\E087";
  //  }
  //}

  //&-square:after {
  //  content: "\E088";
  //}

  //&-star-smooth-o:after {
  //  content: "\E089";
  //}

  //&-star-smooth:after {
  //  content: "\E08A";
  //}

  //&-star:after {
  //  content: "\E08B";
  //}

  //&-stop:after {
  //  content: "\E08C";
  //}

  //&-stormy:after {
  //  content: "\E08D";
  //}

  //&-sunny:after {
  //  content: "\E08E";
  //}

  //&-swap-screen:after {
  //  content: "\E08F";
  //}

  //&-table:after {
  //  content: "\E090";
  //}

  //&-thunderstorm:after {
  //  content: "\E091";
  //}

  //&-ticket-o:after {
  //  content: "\E092";
  //}

  //&-ticket:after {
  //  content: "\E093";
  //}

  //&-tile:after {
  //  content: "\E094";
  //}

  //&-time:after {
  //  content: "\E095";
  //}

  //&-timer:after {
  //  content: "\E096";
  //}

  //&-triangle:after {
  //  content: "\E097";
  //}

  //&-tv-o:after {
  //  content: "\E098";
  //}

  //&-tv:after {
  //  content: "\E099";
  //}

  //&-unlock:after {
  //  content: "\E09A";
  //}

  //&-user-o:after {
  //  content: "\E09B";
  //}

  //&-user:after {
  //  content: "\E09C";
  //}

  //&-veryhot:after {
  //  content: "\E09D";
  //}

  //&-volume-off:after {
  //  content: "\E09E";
  //}

  //&-volume-on:after {
  //  content: "\E09F";
  //}

  //&-wallet:after {
  //  content: "\E0A0";
  //}

  //&-windy:after {
  //  content: "\E0A1";
  //}
}

/** -------------------------------- */
/** ------ Flags ------------------- */
/** -------------------------------- */

.flag-mt {
  .ico-flag {
    padding-bottom: 4px;
  }
}

.ico-flag {
  margin-bottom: 2px;
  &_en {
    background-position: 0 -900px;
  }

  &_de {
    background-position: 0 -636px;
  }

  &_es {
    background-position: 0 -780px;
  }

  &_fr {
    background-position: 0 -876px;
  }

  &_it {
    background-position: 0 -1284px;
  }

  &_he {
    background-position: 0 -1200px;
  }

  &_tr {
    background-position: 0 -2676px;
  }

  //&_world {
  //  background-position: 0 0;
  //}

  //&_ad {
  //  background-position: 0 -12px;
  //}

  //&_ae {
  //  background-position: 0 -24px;
  //}

  //&_af {
  //  background-position: 0 -36px;
  //}

  //&_ag {
  //  background-position: 0 -48px;
  //}

  //&_ai {
  //  background-position: 0 -60px;
  //}

  &_sq {
    background-position: 0 -72px;
  }

  //&_am {
  //  background-position: 0 -84px;
  //}

  //&_an {
  //  background-position: 0 -96px;
  //}

  //&_ao {
  //  background-position: 0 -108px;
  //}

  //&_ar {
  //  background-position: 0 -120px;
  //}

  //&_as {
  //  background-position: 0 -132px;
  //}

  //&_at {
  //  background-position: 0 -144px;
  //}

  //&_au {
  //  background-position: 0 -156px;
  //}

  //&_aw {
  //  background-position: 0 -168px;
  //}

  //&_ax {
  //  background-position: 0 -180px;
  //}

  //&_az {
  //  background-position: 0 -192px;
  //}

  //&_ba {
  //  background-position: 0 -204px;
  //}

  //&_bb {
  //  background-position: 0 -216px;
  //}

  //&_bd {
  //  background-position: 0 -228px;
  //}

  //&_be {
  //  background-position: 0 -240px;
  //}

  //&_bf {
  //  background-position: 0 -252px;
  //}

  //&_bg {
  //  background-position: 0 -264px;
  //}

  //&_bh {
  //  background-position: 0 -276px;
  //}

  //&_bi {
  //  background-position: 0 -288px;
  //}

  //&_bj {
  //  background-position: 0 -300px;
  //}

  //&_bm {
  //  background-position: 0 -312px;
  //}

  //&_bn {
  //  background-position: 0 -324px;
  //}

  //&_bo {
  //  background-position: 0 -336px;
  //}

  //&_br {
  //  background-position: 0 -348px;
  //}

  //&_bs {
  //  background-position: 0 -360px;
  //}

  //&_bt {
  //  background-position: 0 -372px;
  //}

  //&_bw {
  //  background-position: 0 -384px;
  //}

  //&_by {
  //  background-position: 0 -396px;
  //}

  //&_bz {
  //  background-position: 0 -408px;
  //}

  //&_ca {
  //  background-position: 0 -420px;
  //}

  //&_cc {
  //  background-position: 0 -432px;
  //}

  //&_cd {
  //  background-position: 0 -444px;
  //}

  //&_cf {
  //  background-position: 0 -456px;
  //}

  //&_cg {
  //  background-position: 0 -468px;
  //}

  //&_cs11 {
  //  background-position: 0 -480px;
  //}

  //&_ci {
  //  background-position: 0 -492px;
  //}

  //&_ck {
  //  background-position: 0 -504px;
  //}

  //&_cl {
  //  background-position: 0 -516px;
  //}

  //&_cm {
  //  background-position: 0 -528px;
  //}

  &_zh {
    background-position: 0 -540px;
  }

  //&_co {
  //  background-position: 0 -552px;
  //}

  //&_cr {
  //  background-position: 0 -564px;
  //}

  //&_cu {
  //  background-position: 0 -576px;
  //}

  //&_cv {
  //  background-position: 0 -588px;
  //}

  //&_cx {
  //  background-position: 0 -600px;
  //}

  //&_cy {
  //  background-position: 0 -612px;
  //}

  &_cs {
    background-position: 0 -624px;
  }

  //&_dj {
  //  background-position: 0 -648px;
  //}

  //&_dk {
  //  background-position: 0 -660px;
  //}

  //&_dm {
  //  background-position: 0 -672px;
  //}

  //&_do {
  //  background-position: 0 -684px;
  //}

  //&_dz {
  //  background-position: 0 -696px;
  //}

  //&_ec {
  //  background-position: 0 -708px;
  //}

  //&_ee {
  //  background-position: 0 -720px;
  //}

  //&_eg {
  //  background-position: 0 -732px;
  //}

  //&_eh {
  //  background-position: 0 -744px;
  //}

  //&_eng {
  //  background-position: 0 -756px;
  //}

  //&_er {
  //  background-position: 0 -768px;
  //}

  //&_et {
  //  background-position: 0 -792px;
  //}

  //&_eu {
  //  background-position: 0 -804px;
  //}

  //&_fi {
  //  background-position: 0 -816px;
  //}

  //&_fj {
  //  background-position: 0 -828px;
  //}

  //&_fk {
  //  background-position: 0 -840px;
  //}

  //&_fm {
  //  background-position: 0 -852px;
  //}

  //&_fo {
  //  background-position: 0 -864px;
  //}

  //&_ga {
  //  background-position: 0 -888px;
  //}

  //&_gd {
  //  background-position: 0 -912px;
  //}

  //&_ge {
  //  background-position: 0 -924px;
  //}

  //&_gf {
  //  background-position: 0 -936px;
  //}

  //&_gg {
  //  background-position: 0 -948px;
  //}

  //&_gh {
  //  background-position: 0 -960px;
  //}

  //&_gi {
  //  background-position: 0 -972px;
  //}

  //&_gl {
  //  background-position: 0 -984px;
  //}

  //&_gm {
  //  background-position: 0 -996px;
  //}

  //&_gn {
  //  background-position: 0 -1008px;
  //}

  //&_gp {
  //  background-position: 0 -1020px;
  //}

  //&_gq {
  //  background-position: 0 -1032px;
  //}

  &_el {
    background-position: 0 -1044px;
  }

  //&_gs {
  //  background-position: 0 -1056px;
  //}

  //&_gt {
  //  background-position: 0 -1068px;
  //}

  //&_gu {
  //  background-position: 0 -1080px;
  //}

  //&_gw {
  //  background-position: 0 -1092px;
  //}

  //&_gy {
  //  background-position: 0 -1104px;
  //}

  //&_hk {
  //  background-position: 0 -1116px;
  //}

  //&_hn {
  //  background-position: 0 -1128px;
  //}

  &_hr {
    background-position: 0 -1140px;
  }

  //&_ht {
  //  background-position: 0 -1152px;
  //}

  //&_hu {
  //  background-position: 0 -1164px;
  //}

  //&_id {
  //  background-position: 0 -1176px;
  //}

  //&_ie {
  //  background-position: 0 -1188px;
  //}

  //&_im {
  //  background-position: 0 -1212px;
  //}

  //&_in {
  //  background-position: 0 -1224px;
  //}

  //&_io {
  //  background-position: 0 -1236px;
  //}

  //&_iq {
  //  background-position: 0 -1248px;
  //}

  //&_ir {
  //  background-position: 0 -1260px;
  //}

  //&_is {
  //  background-position: 0 -1272px;
  //}

  //&_je {
  //  background-position: 0 -1296px;
  //}

  //&_jm {
  //  background-position: 0 -1308px;
  //}

  //&_jo {
  //  background-position: 0 -1320px;
  //}

  //&_jp {
  //  background-position: 0 -1332px;
  //}

  //&_ke {
  //  background-position: 0 -1344px;
  //}

  //&_kg {
  //  background-position: 0 -1356px;
  //}

  //&_kh {
  //  background-position: 0 -1368px;
  //}

  //&_ki {
  //  background-position: 0 -1380px;
  //}

  //&_km {
  //  background-position: 0 -1392px;
  //}

  //&_kn {
  //  background-position: 0 -1404px;
  //}

  //&_kp {
  //  background-position: 0 -1416px;
  //}

  //&_sr111 {
  //  background-position: 0 -1428px;
  //}

  //&_kw {
  //  background-position: 0 -1440px;
  //}

  //&_ky {
  //  background-position: 0 -1452px;
  //}

  //&_kz {
  //  background-position: 0 -1464px;
  //}

  //&_la {
  //  background-position: 0 -1476px;
  //}

  //&_lb {
  //  background-position: 0 -1488px;
  //}

  //&_lc {
  //  background-position: 0 -1500px;
  //}

  //&_li {
  //  background-position: 0 -1512px;
  //}

  //&_lk {
  //  background-position: 0 -1524px;
  //}

  //&_lr {
  //  background-position: 0 -1536px;
  //}

  //&_ls {
  //  background-position: 0 -1548px;
  //}

  //&_lt {
  //  background-position: 0 -1560px;
  //}

  //&_lu {
  //  background-position: 0 -1572px;
  //}

  //&_lv {
  //  background-position: 0 -1584px;
  //}

  //&_ly {
  //  background-position: 0 -1596px;
  //}

  //&_ma {
  //  background-position: 0 -1608px;
  //}

  //&_mc {
  //  background-position: 0 -1620px;
  //}

  //&_md {
  //  background-position: 0 -1632px;
  //}

  //&_me {
  //  background-position: 0 -1644px;
  //}

  //&_mf {
  //  background-position: 0 -1656px;
  //}

  //&_mg {
  //  background-position: 0 -1668px;
  //}

  //&_mh {
  //  background-position: 0 -1680px;
  //}

  //&_mk {
  //  background-position: 0 -1692px;
  //}

  //&_ml {
  //  background-position: 0 -1704px;
  //}

  //&_mm {
  //  background-position: 0 -1716px;
  //}

  //&_mn {
  //  background-position: 0 -1728px;
  //}

  //&_mo {
  //  background-position: 0 -1740px;
  //}

  //&_mp {
  //  background-position: 0 -1752px;
  //}

  //&_mq {
  //  background-position: 0 -1764px;
  //}

  //&_mr {
  //  background-position: 0 -1776px;
  //}

  //&_ms {
  //  background-position: 0 -1788px;
  //}

  //&_mt {
  //  background-position: 0 -1800px;
  //}

  //&_mu {
  //  background-position: 0 -1812px;
  //}

  //&_mv {
  //  background-position: 0 -1824px;
  //}

  //&_mw {
  //  background-position: 0 -1836px;
  //}

  //&_mx {
  //  background-position: 0 -1848px;
  //}

  //&_my {
  //  background-position: 0 -1860px;
  //}

  //&_mz {
  //  background-position: 0 -1872px;
  //}

  //&_na {
  //  background-position: 0 -1884px;
  //}

  //&_nc {
  //  background-position: 0 -1896px;
  //}

  //&_ne {
  //  background-position: 0 -1908px;
  //}

  //&_nf {
  //  background-position: 0 -1920px;
  //}

  //&_ng {
  //  background-position: 0 -1932px;
  //}

  //&_ni {
  //  background-position: 0 -1944px;
  //}

  //&_nir {
  //  background-position: 0 -1956px;
  //}

  //&_nl {
  //  background-position: 0 -1968px;
  //}

  //&_no {
  //  background-position: 0 -1980px;
  //}

  //&_np {
  //  background-position: 0 -1992px;
  //}

  //&_nr {
  //  background-position: 0 -2004px;
  //}

  //&_nu {
  //  background-position: 0 -2016px;
  //}

  //&_nz {
  //  background-position: 0 -2028px;
  //}

  //&_om {
  //  background-position: 0 -2040px;
  //}

  //&_pa {
  //  background-position: 0 -2052px;
  //}

  //&_pe {
  //  background-position: 0 -2064px;
  //}

  //&_pf {
  //  background-position: 0 -2076px;
  //}

  //&_pg {
  //  background-position: 0 -2088px;
  //}

  //&_ph {
  //  background-position: 0 -2100px;
  //}

  //&_pk {
  //  background-position: 0 -2112px;
  //}

  &_pl {
    background-position: 0 -2124px;
  }

  //&_pm {
  //  background-position: 0 -2136px;
  //}

  //&_pn {
  //  background-position: 0 -2148px;
  //}

  //&_pr {
  //  background-position: 0 -2160px;
  //}

  //&_ps {
  //  background-position: 0 -2172px;
  //}

  &_pt {
    background-position: 0 -2184px;
  }

  //&_pw {
  //  background-position: 0 -2196px;
  //}

  //&_s11r {
  //  background-position: 0 -2208px;
  //}

  //&_qa {
  //  background-position: 0 -2220px;
  //}

  //&_re {
  //  background-position: 0 -2232px;
  //}

  &_ro {
    background-position: 0 -2244px;
  }

  &_sr {
    background-position: 0 -2256px;
  }

  &_ru {
    background-position: 0 -2268px;
  }

  //&_rw {
  //  background-position: 0 -2280px;
  //}

  //&_sa {
  //  background-position: 0 -2292px;
  //}

  //&_sb {
  //  background-position: 0 -2304px;
  //}

  //&_sco {
  //  background-position: 0 -2316px;
  //}

  //&_sc {
  //  background-position: 0 -2328px;
  //}

  //&_sd {
  //  background-position: 0 -2340px;
  //}

  //&_sr11 {
  //  background-position: 0 -2352px;
  //}

  //&_sg {
  //  background-position: 0 -2364px;
  //}

  //&_sq1 {
  //  background-position: 0 -2376px;
  //}

  //&_si {
  //  background-position: 0 -2388px;
  //}

  //&_sj {
  //  background-position: 0 -2400px;
  //}

  //&_sq1 {
  //  background-position: 0 -2412px;
  //}

  //&_sl {
  //  background-position: 0 -2424px;
  //}

  //&_sm {
  //  background-position: 0 -2436px;
  //}

  //&_sn {
  //  background-position: 0 -2448px;
  //}

  //&_so {
  //  background-position: 0 -2460px;
  //}

  //&_sr {
  //  background-position: 0 -2472px;
  //}

  //&_ss {
  //  background-position: 0 -2484px;
  //}

  //&_sq1 {
  //  background-position: 0 -2496px;
  //}

  //&_sv {
  //  background-position: 0 -2508px;
  //}

  //&_sy {
  //  background-position: 0 -2520px;
  //}

  //&_sz {
  //  background-position: 0 -2532px;
  //}

  //&_tc {
  //  background-position: 0 -2544px;
  //}

  //&_td {
  //  background-position: 0 -2556px;
  //}

  //&_tf {
  //  background-position: 0 -2568px;
  //}

  //&_tg {
  //  background-position: 0 -2580px;
  //}

  //&_th {
  //  background-position: 0 -2592px;
  //}

  //&_tj {
  //  background-position: 0 -2604px;
  //}

  //&_tk {
  //  background-position: 0 -2616px;
  //}

  //&_tl {
  //  background-position: 0 -2628px;
  //}

  //&_tm {
  //  background-position: 0 -2640px;
  //}

  //&_tn {
  //  background-position: 0 -2652px;
  //}

  //&_to {
  //  background-position: 0 -2664px;
  //}

  //&_tt {
  //  background-position: 0 -2688px;
  //}

  //&_tv {
  //  background-position: 0 -2700px;
  //}

  //&_tw {
  //  background-position: 0 -2712px;
  //}

  //&_tz {
  //  background-position: 0 -2724px;
  //}

  &_uk {
    background-position: 0 -2736px;
  }

  //&_ug {
  //  background-position: 0 -2748px;
  //}

  //&_us {
  //  background-position: 0 -2760px;
  //}

  //&_uy {
  //  background-position: 0 -2772px;
  //}

  //&_uz {
  //  background-position: 0 -2784px;
  //}

  //&_va {
  //  background-position: 0 -2796px;
  //}

  //&_vc {
  //  background-position: 0 -2808px;
  //}

  //&_ve {
  //  background-position: 0 -2820px;
  //}

  //&_vg {
  //  background-position: 0 -2832px;
  //}

  &_vi {
    background-position: 0 -2844px;
  }

  //&_vn {
  //  background-position: 0 -2856px;
  //}

  //&_vu {
  //  background-position: 0 -2868px;
  //}

  //&_wal {
  //  background-position: 0 -2880px;
  //}

  //&_wf {
  //  background-position: 0 -2892px;
  //}
  //
  //&_ws {
  //  background-position: 0 -2904px;
  //}

  //&_xk {
  //  background-position: 0 -2916px;
  //}

  //&_ye {
  //  background-position: 0 -2928px;
  //}

  //&_yt {
  //  background-position: 0 -2940px;
  //}

  //&_za {
  //  background-position: 0 -2952px;
  //}

  //&_zm {
  //  background-position: 0 -2964px;
  //}

  //&_zw {
  //  background-position: 0 -2976px;
  //}
}

@import "effects";

// Theme styles
@import "~@theme/style/style.scss";
