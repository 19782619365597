@import "~@/style/global.scss";

#app {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-x: hidden;
}

.iframe {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999999;
  background: white;
  border: 0;
  outline: none;
}
