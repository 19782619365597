@import "~@/style/global.scss";

* {
  font-family: $base-font-family;
}

p {
  color: $white-color;
}

.member-card-page {
  padding: 32px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.member-card-wrapper {
  max-width: 375px;
}

.member-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 63%;

  border-radius: 2.34% / 3.72%;
  // @link https://material-components.github.io/material-components-web-catalog/#/component/elevation
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.member-card--front {
  border-width: 1px;
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  background-image: url(~@theme/images/logo.png);
  background-color: $btn-primary-color;
  z-index: 100;
}

.member-card--back {
  // 63.00% — 100% height of the card
  // 50.40% —  80% height of the card
  margin-top: -50.4%;
  background-color: #fff;
  color: black;
  z-index: 80;
  transition: margin-top 0.3s ease-in-out;
}

.member-card--open {
  // 06.30% —  10% height of the card
  margin-top: -2.3%;
}

.member-card__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  z-index: 90;
}

.member-card__barcode {
  padding: 0 16px 0 16px;
  height: 65%;
  width: 100%;
  box-sizing: border-box;
}

.member-card__pin {
  display: flex;
  justify-content: center;
  padding: 15px 4px 4px 4px;
  width: 100%;
  font-size: $font-xm-size;

  span {
    margin-left: 8px;
    font-weight: 700;
  }
}

.member-card__refresh {
  margin-left: 12px;
  cursor: pointer;
}

.member-card__barcode canvas {
  max-width: 100%;
  max-height: 100%;
}

.member-card__human-title {
  bottom: 0;
  height: 15%;

  font-size: 18px;
}

.member-card__barcode,
.member-card__human-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.member-card-button {
  color: $text-color;
  font-family: $second-font-family;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 32px auto 0 auto;
  padding: 8px 32px;

  transition: opacity 0.25s ease-in-out;
}

.member-card-button--link {
  font-weight: 400;
  text-transform: none;
  margin-top: 8px;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  box-shadow: none;
  text-decoration: underline;
}

.member-card-button--hidden {
  opacity: 0;
}

.member-card-share-icon {
  display: inline-block;
  height: 1rem;
  fill: white;
  vertical-align: text-bottom;
}
