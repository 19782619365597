@import "~@/style/global.scss";

.mobile-footer {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: absolute;

  @media (orientation: portrait) {
    padding: $m_footer-padding;
  }

  @media (orientation: landscape) {
    padding: $m_footer-padding * 0.8;
  }

  @media (min-width: 1025px) {
    display: none;
  }
}

.mobile-footer__side {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-child {
    .mobile-footer__content {
      text-align: left;
    }
  }

  @media (orientation: landscape) {
    &:first-child {
      .mobile-footer__content {
        margin-left: 25px;
      }
    }
  }

  @media (orientation: portrait) {
    &:first-child {
      .mobile-footer__content {
        left: 20px;
        right: auto;
        font-size: 22px;
      }
    }
  }
}

.mobile-footer__button {
  min-width: $m_footer-btn-width;
  height: $m_footer-btn-height;
  font-size: $font-lg-size;
  background-size: contain;
}

.mobile-footer__content {
  display: flex;
  text-align: right;
  color: $text-color;
  font-family: $base-font-family;

  @media (orientation: portrait) {
    top: -20px;
    right: 20px;
    height: 34px;
    align-items: flex-end;
    flex-direction: column;
    font-size: $font-xs-size;
    position: absolute;
  }

  @media (orientation: landscape) {
    flex-direction: column;
    font-size: $base-font-size;
    margin-right: 25px;
  }
}
