@import "~@/style/global.scss";

.tag {
  &--aviator {
    width: 55px;
    height: 15px;

    svg {
      color: #e50539;
      width: 100%;
      height: 100%;
    }
  }

  &--christmas {
    width: 80px;
    height: 20px;

    svg {
      transform: scale(1.2);
      width: 100%;
      height: 100%;
      color: #42ff00;
    }
  }

  &--chinese {
    width: 120px;
    height: 20px;

    svg {
      transform: scale(0.95);
      width: 100%;
      height: 100%;
      color: #efb717;
    }
  }

  &--halloween {
    width: 58px;
    height: 10px;

    svg {
      transform: scale(1.6);
      color: #ff7a00;
      width: 100%;
      height: 100%;
    }
  }
}

.desktop-menu {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 80px;

  &__separator {
    flex: 1;
  }

  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 0;
  }

  &__item {
    width: 120px;
    height: 60px;
    padding: 4px 20px;

    & + & {
      margin-left: -1px;
    }

    &::v-deep.btn--disabled {
      background-color: $null-color;
      opacity: 0.35;
    }

    &--recommendation {
      width: 165px;
      text-wrap: balance;
      word-break: break-word;
    }
  }

  &__demo {
    top: 0;
    bottom: 0;
    right: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1366px) {
    padding: 0 160px;
  }
}

.search {
  flex: 1;
  height: 52px;
  position: relative;

  &__input {
    width: 120px;
    height: 60px;
    min-width: 100px;
    color: $text-color;
    border: none;
    font-size: $font-xxs-size;
    font-family: $base-font-family;
    text-transform: uppercase;
    background-color: transparent;
    background-image: url($path + "search-icon.png"),
      linear-gradient(0deg, $null-color 0%, fade-out($bg-white-color, 0.4) 50%, $null-color 100%);
    background-size: 16px 16px, 1px 100%;
    background-position: 8px center, right center;
    background-repeat: no-repeat;
    transition: width 0.25s ease, min-width 0.25s ease, padding-right 0.25s ease;
    position: relative;
    box-sizing: border-box;
    outline: none;
    padding: 0 8px 0 32px;
  }

  &__reset-button {
    top: 10px;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    display: none;
    background-image: url($path + "cross-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    position: absolute;
    cursor: pointer;
  }

  &--expanded {
    .search__input {
      width: 100%;
      min-width: 119px;
      padding-right: 32px;
    }

    .search__reset-button {
      display: block;
    }
  }
}
