// Colors
$white-color: #fff;
$dark-color: #000;
$choco-color: #40340a;
$brown-color: #806914;
$gold-color: #fed128;
$tomato-color: #800e0b;
$red-color: #fa1c16;
$coral-color: #f40a35;
$pink-color: #e754db;
$null-color: transparent;

// Main
$bg-main-color: #0035ba;

// Swiper navigation
$icon-nav-color: #28a9f5;

// Adjust hue
$hue-sm-deg: -21deg;
$hue-md-deg: $hue-sm-deg * 1.65;
$hue-lg-deg: $hue-sm-deg * 1.9;

// Import theme colors
@import "~@theme/style/colors.scss";

// Backgrounds
$bg-white-color: $white-color;
$bg-black-color: $dark-color;
$bg-darkGray-color: #2b2d31;
$bg-gray-color: #414141;
$bg-header-color: adjust-hue(lighten($bg-main-color, 30%), $hue-sm-deg);
$bg-bright-color: lighten($bg-main-color, 29.1%);
$bg-contrast-color: lighten($bg-main-color, 23.2%);
$bg-dark-color: darken($bg-main-color, 2.5%);
$bg-darkRed-color: darken($red-color, 37.4%);

// Buttons
$btn-tag-color: saturate(darken($bg-main-color, 20%), 50%);
$btn-activeTag-color: desaturate(lighten($btn-tag-color, 15%), 20%);
$btn-primary-color: saturate(darken($bg-main-color, 20%), 50%);
$btn-secondary-color: #0e6b17;
$btn-cancel-color: #640c0c;
$btn-disabled-color: #1a1a1a;
$btn-game-color: adjust-hue(lighten($btn-primary-color, 13%), -7%);

$btn-primary-color: saturate(darken($bg-main-color, 20%), 50%);
$btn-secondary-color: #0e6b17;

// Buttons borders
$border-primary-color: lighten($btn-primary-color, 3%);
$border-primary2-color: desaturate(lighten($bg-main-color, 10%), 30%);
$border-secondary-color: darken($btn-secondary-color, 5%);
$border-secondary2-color: desaturate(lighten($btn-secondary-color, 20%), 30%);
$border-cancel-color: darken($btn-cancel-color, 5%);
$border-cancel2-color: desaturate(lighten($btn-cancel-color, 30%), 30%);

// Borders
$border-gray-color: $bg-darkGray-color;

// Shadow
$shadow-opacity: 0.35;
$shadow-color: lighten($bg-main-color, 8%);
$shadow-dark-color: darken($bg-main-color, 13.5%);
$shadow-light-color: lighten($bg-main-color, 17%);
$shadow-bright-color: adjust-hue(lighten($bg-main-color, 15%), $hue-sm-deg * 0.7);
$shadow-lighten-color: adjust-hue(lighten($bg-main-color, 40.7%), $hue-lg-deg);
$shadow-brighten-color: adjust-hue(lighten($bg-main-color, 28.2%), $hue-sm-deg * 0.2);
$shadow-darkDim-color: adjust-hue(darken($bg-main-color, 23.5%), $hue-md-deg);
$shadow-dim-color: adjust-hue(darken($bg-main-color, 10%), $hue-md-deg);
$shadow-gray-color: #414141;
$shadow-tomato-color: $tomato-color;
$shadow-coral-color: $coral-color;
$shadow-red-color: $red-color;
$shadow-pink-color: $pink-color;

// Typography
$text-color: $white-color;
$text-bright-color: #f1f1f1;
$text-light-color: adjust-hue(lighten($bg-main-color, 30%), $hue-sm-deg * 1.5);
$text-dim-color: adjust-hue(darken($bg-main-color, 10%), $hue-sm-deg * 1.5);
$text-main-color: lighten($bg-main-color, 26.5%);
$text-darkGray-color: $bg-darkGray-color;
$text-gray-color: gray;
$text-gold-color: $gold-color;
$text-brown-color: $brown-color;
$text-coral-color: lighten($coral-color, 11.65%);

// Typography
$game-card-bg: darken($bg-main-color, 25%);
